import { motion } from 'framer-motion';
import classNames from 'classnames';
import './AnimatedText.scss';

type AnimatedTextProps = {
  text: string;
  speed?: number;
  delay?: number;
};

export default function AnimatedText({
  text,
  speed = 0.1,
  delay = 0,
}: AnimatedTextProps) {
  return (
    <>
      {text.split('').map((letter, index) => (
        <motion.div
          key={letter}
          initial={{ opacity: 0, transform: 'translate3d(0, 25px, 0)' }}
          animate={{ opacity: 1, transform: 'translate3d(0, 0, 0)' }}
          transition={{ delay: speed * index + delay }}
          className={classNames('animated-text__letter', {
            'animated-text__letter--whitespace': letter === ' ',
          })}
        >
          {letter}
        </motion.div>
      ))}
    </>
  );
}
