import LockIcon from 'assets/component-icons/LockIcon';
import UserIcon from 'assets/component-icons/UserIcon';
import Layout, { LayoutHeader } from 'components/layout/Layout';
import SelectBlock from 'components/select-block/SelectBlock';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Routes from 'Routes';
import { goToHRAdmin } from 'utils/redirections';
import './Segmentation.scss';
import { SegmentationTranslations } from './Segmentation.translations';

export default function Segmentation() {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  function onAdminClick() {
    goToHRAdmin();
  }

  function continueOnProfileCompletion() {
    navigate(Routes.profileCompletion);
  }

  return (
    <Layout withAsideImage>
      <LayoutHeader>
        <h1>{formatMessage(SegmentationTranslations.title)}</h1>
        <p>{formatMessage(SegmentationTranslations.subtitle)}</p>
      </LayoutHeader>

      <div className="segmentation-page">
        <SelectBlock
          icon={LockIcon}
          title={formatMessage(SegmentationTranslations.hrAdminTitle)}
          description={formatMessage(
            SegmentationTranslations.hrAdminDescription,
          )}
          onClick={onAdminClick}
        />

        <SelectBlock
          icon={UserIcon}
          title={formatMessage(SegmentationTranslations.mjgTitle)}
          description={formatMessage(SegmentationTranslations.mjgDescription)}
          onClick={continueOnProfileCompletion}
        />
      </div>
    </Layout>
  );
}
