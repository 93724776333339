export function setCookie(
  name: string,
  value: string,
  ttl: number,
  cookieDomain: string | null,
) {
  let expires = '';
  if (ttl) {
    const date = new Date();
    date.setTime(date.getTime() + ttl * 60 * 1000);
    const dateString = date.toUTCString();
    expires = `; expires=${dateString}`;
  }

  let domain = '';
  if (cookieDomain) {
    domain = `; domain=${cookieDomain}`;
  }

  const escapedvalue = escape(value);
  document.cookie = `${name}=${escapedvalue}${domain}${expires}; path=/`;
}

export function getCookie(name: string) {
  const nameLenPlus = name.length + 1;
  return (
    document.cookie
      .split(';')
      .map((c) => c.trim())
      .filter((cookie) => cookie.substring(0, nameLenPlus) === `${name}=`)
      .map((cookie) => decodeURIComponent(cookie.substring(nameLenPlus)))[0] ||
    null
  );
}
