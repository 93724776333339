import { defineMessages } from 'react-intl';

export const FreeAmbassadorUnpublicationModalTranslations = defineMessages({
  title: {
    id: 'FreeAmbassadorUnpublicationModalTranslations.title',
    defaultMessage: 'Dépublication de votre profil',
  },
  subtitle: {
    id: 'FreeAmbassadorUnpublicationModalTranslations.subtitle',
    defaultMessage:
      'Si vous choisissez de vous dépublier :',
  },
  content: {
    id: 'FreeAmbassadorUnpublicationModalTranslations.content',
    defaultMessage: `
    <ul>
    <li>Vous ne serez plus Ambassadeur visible sur le moteur de recherche My Job Glasses et vous ne pourrez plus vous faire contacter par des membres</li>
    <li>Vous pourrez continuer d’échanger avec les membres qui vous ont déjà contacté</li>
    <li>Vous pourrez toujours contacter des Ambassadeurs en tant que membre</li>
    </ul>
    Si vous souhaitez ne plus recevoir de messages, vous pouvez supprimer votre compte depuis <a href={settingsUrl}>la page paramètres</a>.
    `,
  },
  validate: {
    id: 'FreeAmbassadorUnpublicationModalTranslations.validate',
    defaultMessage: 'Valider',
  },
  cancel: {
    id: 'FreeAmbassadorUnpublicationModalTranslations.cancel',
    defaultMessage: 'Annuler',
  },
});
