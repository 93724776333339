import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { networkErrorAppsignalHandler } from '../appsignal/networkErrorHandler';

const opts = {
  uri: `${window.__RUNTIME_CONFIG__.REACT_APP_HERMES_URL || ''}/graphql`,
  credentials: 'include',
};

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: BatchHttpLink.from([
    networkErrorAppsignalHandler,
    createUploadLink(opts) as unknown as ApolloLink,
    new BatchHttpLink(opts),
  ]),
});

export default client;
