import { defineMessages } from 'react-intl';

export default defineMessages({
  success: {
    id: 'ConfirmEmail.success',
    defaultMessage: 'Félicitations, votre adresse email a bien été confirmée.',
  },
  error: {
    id: 'ConfirmEmail.error',
    defaultMessage: 'Une erreur est survenue, veuillez nous contacter.',
  },
});
