import { MessageDescriptor } from 'react-intl';

export type Hint = {
  message: MessageDescriptor;
  isValid: (password: string) => boolean;
};

export function containsAtLeast8Characters(password: string) {
  return password.length >= 8;
}

export function containsAtLeast1UppercaseLetter(password: string) {
  return /[A-Z]/.test(password);
}

export function containsAtLeast1LowercaseLetter(password: string) {
  return /[a-z]/.test(password);
}

export function containsAtLeast1Number(password: string) {
  return /[0-9]/.test(password);
}

export function containsAtLeast1SpecialCharacter(password: string) {
  return /[^A-Za-z0-9]/.test(password);
}

export function computePasswordStrengthPercentage(password: string) {
  const validations = [
    containsAtLeast8Characters,
    containsAtLeast1UppercaseLetter,
    containsAtLeast1LowercaseLetter,
    containsAtLeast1Number,
    containsAtLeast1SpecialCharacter,
  ];

  const validTestCount = validations.reduce(
    (count, validationFunction) =>
      validationFunction(password) ? count + 1 : count,
    0,
  );

  return Math.ceil((validTestCount / validations.length) * 100);
}
