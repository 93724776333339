import { defineMessages } from 'react-intl';

export default defineMessages({
  helper: {
    id: 'MandatoryPasswordSpecs.helper',
    defaultMessage:
      "Définissez un mot de passe fort ou très fort. <link>Besoin d'aide ?</link>",
    description: 'helper avec le lien pour créer un mdp fort',
  },
  uppercaseCharacter: {
    id: 'MandatoryPasswordSpecs.uppercaseCharacter',
    defaultMessage: '1 caractère majuscule',
    description: '1 caractère majuscule',
  },
  lowercaseCharacter: {
    id: 'MandatoryPasswordSpecs.lowercaseCharacter',
    defaultMessage: '1 caractère minuscule',
    description: '1 caractère minuscule',
  },
  digitCharacter: {
    id: 'MandatoryPasswordSpecs.digitCharacter',
    defaultMessage: '1 chiffre',
    description: '1 chiffre',
  },
  specialCharacter: {
    id: 'MandatoryPasswordSpecs.specialCharacter',
    defaultMessage: '1 caractère spécial',
    description: '1 caractère spécial',
  },
  minimumLength: {
    id: 'MandatoryPasswordSpecs.minimumLength',
    defaultMessage: '8 caractères minimum',
    description: '8 caractères minimum',
  },
});
