import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { FreeAmbassadorUnpublicationModalTranslations } from './FreeAmbassadorUnpublicationModal.translations';
import ButtonsGroup from 'components/buttons-group/ButtonsGroup';
import SecondaryButton from 'components/secondary-button/SecondaryButton';
import PrimaryButton from 'components/primary-button/PrimaryButton';
import { UserContext } from 'contexts/user/UserContext';
import { getMarketplaceSettingsUrl } from 'utils/redirections';

type FreeAmbassadorUnpublicationModalProps = {
  handleUnpublishProfile: () => void;
  handleCloseModal: () => void;
};

export default function PaidAmbassadorUnpublicationModal({
  handleUnpublishProfile,
  handleCloseModal,
}: FreeAmbassadorUnpublicationModalProps) {
  const { formatMessage } = useIntl();
  const { user } = useContext(UserContext);

  return (
    <div>
      <p
        dangerouslySetInnerHTML={{
          __html: formatMessage(
            FreeAmbassadorUnpublicationModalTranslations.subtitle,
          ),
        }}
      />
      <p
        dangerouslySetInnerHTML={{
          __html: formatMessage(
            FreeAmbassadorUnpublicationModalTranslations.content,
            {
              settingsUrl: getMarketplaceSettingsUrl(),
            },
          ),
        }}
      />

      <ButtonsGroup>
        <SecondaryButton
          label={formatMessage(
            FreeAmbassadorUnpublicationModalTranslations.cancel,
          )}
          onClick={handleCloseModal}
        />
        <PrimaryButton
          label={formatMessage(
            FreeAmbassadorUnpublicationModalTranslations.validate,
          )}
          onClick={handleUnpublishProfile}
        />
      </ButtonsGroup>
    </div>
  );
}
