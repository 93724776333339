import { useMutation } from '@apollo/client';
import { useProfileCompletionContext } from '../ProfileCompletionContext.hook';
import { UPDATE_USER_PROFILE_COMPLETION } from './gql/ProfileCompletionUserUpdate.gql';
import {
  AvailableLanguagesEnum,
  UpdateUserProfileCompletionMutation,
  UpdateUserProfileCompletionMutationVariables,
} from '../../../@types/graphql';
import { useLanguage } from 'hooks/use-languages/useLanguages';

type UseProfileCompletionUserUpdateOptions = {
  dry?: boolean;
};

export default function useProfileCompletionUserUpdate(
  options?: UseProfileCompletionUserUpdateOptions,
) {
  const language = useLanguage();

  const { user, updateUserCompletion } = useProfileCompletionContext();
  const [updateUser] = useMutation<
    UpdateUserProfileCompletionMutation,
    UpdateUserProfileCompletionMutationVariables
  >(UPDATE_USER_PROFILE_COMPLETION, {
    onCompleted: (data) => {
      if (!data.updateUser || options?.dry) return;
      updateUserCompletion(data.updateUser);
    },
  });

  return (
    variables: Omit<UpdateUserProfileCompletionMutationVariables, 'userId'>,
  ) =>
    updateUser({
      variables: {
        locale:
          language.language === 'fr'
            ? AvailableLanguagesEnum.Fr
            : AvailableLanguagesEnum.En,
        userId: user?.id || '',
        ...variables,
      },
    });
}
