import classNames from 'classnames';
import { useState } from 'react';
import { InputValue } from '../types/BaseInputValue';
import './RadioInput.scss';

type RadioInputProps<T> = {
  label?: string;
  name: string;
  values: InputValue<T>[];
  error?: string;
  onSelection: (value: T) => void;
  inline?: boolean;
  initialSelectedIndex?: number;
  dataCy?: string;
};

export default function RadioInput<T extends string>({
  label,
  name,
  error,
  values,
  onSelection,
  inline,
  initialSelectedIndex,
  dataCy,
}: RadioInputProps<T>) {
  const [selectedValue, setSelectedValue] = useState(initialSelectedIndex);

  return (
    <div
      className={classNames('radio-input', { 'radio-input--inline': inline })}
    >
      {label && (
        <label
          className="radio-input__label"
          htmlFor={name}
        >
          {label}
        </label>
      )}
      {values.map(({ icon: Icon, translation, value }, index) => (
        <label
          data-cy={dataCy}
          key={value}
          htmlFor={value}
          className="radio-input__radio"
        >
          <input
            type="radio"
            name={name}
            id={value}
            value={value}
            checked={selectedValue === index}
            onClick={() => {
              onSelection(value);
              setSelectedValue(index);
            }}
          />
          <span className="radio-input__radio__checkmark" />
          {Icon && <Icon className="radio-input__radio__icon" />}
          {translation}
        </label>
      ))}
      {error && <div className="radio-input__error">{error}</div>}
    </div>
  );
}
