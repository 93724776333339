import { DetailedHTMLProps, ReactNode } from 'react';
import './CheckboxInput.scss';

// eslint-disable-next-line max-len
type DefaultInputProps = Pick<
  DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  'onChange' | 'checked'
>;
type CheckboxInputProps = DefaultInputProps & {
  label: string | ReactNode;
  name: string;
  error?: string;
};

export default function CheckboxInput({
  label,
  name,
  error,
  checked,
  onChange,
}: CheckboxInputProps) {
  return (
    <div className="checkbox-input">
      <label
        htmlFor={name}
        className="checkbox-input__checkbox"
      >
        <input
          data-cy="checkbox"
          type="checkbox"
          name={name}
          id={name}
          checked={checked}
          onChange={onChange}
        />
        <span className="checkbox-input__checkbox__checkmark" />
        <span>{label}</span>
      </label>
      {error && <div className="checkbox-input__error">{error}</div>}
    </div>
  );
}
