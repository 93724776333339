const MARKETPLACE_FRONTEND_URL =
  window.__RUNTIME_CONFIG__.REACT_APP_APP_FRONTEND_URL;

export function goToMarketplace() {
  if (MARKETPLACE_FRONTEND_URL) {
    window.location.href = `${MARKETPLACE_FRONTEND_URL}/search`;
  }
}

export function getMarketplaceDashboardUrl() {
  return `${MARKETPLACE_FRONTEND_URL}/dashboard`;
}

export function goToMarketplaceDashboard() {
  if (MARKETPLACE_FRONTEND_URL) {
    window.location.href = getMarketplaceDashboardUrl();
  }
}

export function getMarketplaceSettingsUrl() {
  return `${MARKETPLACE_FRONTEND_URL}/settings`;
}

export function goToMarketplaceSettings() {
  if (MARKETPLACE_FRONTEND_URL) {
    window.location.href = getMarketplaceSettingsUrl();
  }
}

export function goToHRAdmin() {
  if (MARKETPLACE_FRONTEND_URL) {
    window.location.href = `${MARKETPLACE_FRONTEND_URL}/company-admin`;
  }
}
