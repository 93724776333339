import { useMutation } from '@apollo/client';
import { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { FormikHelpers } from 'formik';
import { CONFIRM_EMAIL_ERRORS } from '../../../@types/graphql-errors';
import {
  Request_Email_Adress_Confirmation_EmailMutation,
  Request_Email_Adress_Confirmation_EmailMutationVariables,
} from '../../../@types/graphql';
import { handleGraphqlErrorsOnFormik } from '../../../utils/formik';
import PageLoaderContext from '../../../contexts/pageLoader';
import { beforeMutation } from '../../../hooks/use-submit-mutation-wrapper/useSubmitMutationWrapper';
import { REQUEST_EMAIL_ADRESS_CONFIRMATION_EMAIL } from './MustConfirmEmail.gql';
import { ErrorsTranslations } from 'i18n/errors.translations';

interface FormikFields {
  email: string;
  tutorEmail?: string;
  mustConfirmTutorEmail?: boolean;
}

export function useRequestEmailAddressConfirmationEmail(
  setSentStatus: (value: boolean) => void,
) {
  const intl = useIntl();
  const { setLoading } = useContext(PageLoaderContext);

  const [requestEmailAddressConfirmationEmail, { loading }] = useMutation<
    Request_Email_Adress_Confirmation_EmailMutation,
    Request_Email_Adress_Confirmation_EmailMutationVariables
  >(REQUEST_EMAIL_ADRESS_CONFIRMATION_EMAIL);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  return function onSubmit(
    values: FormikFields,
    formikBag: FormikHelpers<FormikFields>,
  ) {
    return beforeMutation<FormikFields>(
      formikBag,
      requestEmailAddressConfirmationEmail({
        variables: {
          email: values.mustConfirmTutorEmail
            ? values.tutorEmail
            : values.email,
        },
      })
        .then(() => {
          setSentStatus(true);
        })
        .catch((error) => {
          handleGraphqlErrorsOnFormik<
            FormikFields,
            Request_Email_Adress_Confirmation_EmailMutationVariables,
            CONFIRM_EMAIL_ERRORS
          >(error, formikBag, {
            translations: {
              default: intl.formatMessage(ErrorsTranslations.default),
              network: intl.formatMessage(ErrorsTranslations.network),
              unknown: intl.formatMessage(ErrorsTranslations.default),
            },
          });
        }),
    );
  };
}
