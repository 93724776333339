import qs from 'qs';

interface Tokens {
  sign_up_token?: string;
  invitation_token?: string;
  school_token?: string;
  reset_password_token?: string;
  confirmation_token?: string;
}
export function useTokens(search: string | undefined | null) {
  const params: Tokens = search ? qs.parse(search.substr(1)) : {};

  return {
    resetPasswordToken: params?.reset_password_token,
    invitationToken:
      params?.sign_up_token || params?.school_token || params?.invitation_token,
    confirmationToken: params?.confirmation_token,
  };
}
