import { UserPartsFragment } from '../../../../../@types/graphql';
import XIcon from 'assets/component-icons/XIcon';
import './GalleryImage.scss';

type GalleryImageProps = {
  image: UserPartsFragment['galleryImages'][0];
  onImageDeletion: (imageId: string) => void;
};

export default function GalleryImage({
  image,
  onImageDeletion,
}: GalleryImageProps) {
  return (
    <div className="gallery-image">
      <img src={image.imageUrl} />

      <button
        type="button"
        onClick={() => onImageDeletion(image.id)}
      >
        <XIcon />
      </button>
    </div>
  );
}
