import { defineMessages } from 'react-intl';
import { DiplomaEnum } from '../@types/graphql';

export const DiplomaTypeTranslations = defineMessages({
  [DiplomaEnum.Cep]: {
    id: 'DiplomaType.Cep',
    defaultMessage: "CEP (certificat d'études primaires)",
  },
  [DiplomaEnum.Brevet]: {
    id: 'DiplomaType.Brevet',
    defaultMessage: 'Brevet',
  },
  [DiplomaEnum.Cap]: {
    id: 'DiplomaType.Cap',
    defaultMessage: 'CAP',
  },
  [DiplomaEnum.Bep]: {
    id: 'DiplomaType.Bep',
    defaultMessage: 'BEP',
  },
  [DiplomaEnum.BacGt]: {
    id: 'DiplomaType.BacGt',
    defaultMessage: 'Bac (général ou technologique ou DAEU)',
  },
  [DiplomaEnum.BacPro]: {
    id: 'DiplomaType.BacPro',
    defaultMessage: 'Bac professionnel',
  },
  [DiplomaEnum.Bac_2]: {
    id: 'DiplomaType.Bac_2',
    defaultMessage:
      'Bac +2 (BTS, DUT, Deug, Deust ou diplôme de niveau équivalent)',
  },
  [DiplomaEnum.Bac_3]: {
    id: 'DiplomaType.Bac_3',
    defaultMessage: 'Bac +3 (Licence ou diplôme de niveau équivalent)',
  },
  [DiplomaEnum.Bac_4]: {
    id: 'DiplomaType.Bac_4',
    defaultMessage: 'Bac +4 (Maitrise ou diplôme de niveau équivalent)',
  },
  [DiplomaEnum.Bac_5]: {
    id: 'DiplomaType.Bac_5',
    defaultMessage:
      'Bac +5 (Master, DEA, DESS, diplôme de grandes écoles de niveau équivalent)',
  },
  [DiplomaEnum.Doctorat]: {
    id: 'DiplomaType.Doctorat',
    defaultMessage: 'Doctorat',
  },
  [DiplomaEnum.None]: {
    id: 'DiplomaType.None',
    defaultMessage: 'Aucun',
  },
});
