import { defineMessages } from 'react-intl';

export const PaidAmbassadorUnpublicationModalTranslations = defineMessages({
  title: {
    id: 'PaidAmbassadorUnpublicationModalTranslations.title',
    defaultMessage: 'Dépublication de votre profil',
  },
  subtitle: {
    id: 'PaidAmbassadorUnpublicationModalTranslations.subtitle',
    defaultMessage:
      'Vous êtes publié sur My Job Glasses dans le cadre du programme Ambassadeur de My Job Glasses',
  },
  content: {
    id: 'PaidAmbassadorUnpublicationModalTranslations.content',
    defaultMessage: `
    Vous pouvez :
    <ul>
    <li>Hiberner votre profil temporairement si vous n'êtes pas disponible</li>
    <li>Demander à votre administrateur RH de dépublier votre profil</li>
    </ul>
    `,
  },
  hibernate: {
    id: 'PaidAmbassadorUnpublicationModalTranslations.hibernate',
    defaultMessage: 'Hiberner mon profil',
  },
  contactHR: {
    id: 'PaidAmbassadorUnpublicationModalTranslations.contactHR',
    defaultMessage: 'Contacter mon admin RH',
  },
});
