import { useContext, useMemo, useState } from 'react';
import {
  PrimarySituation,
  PrimarySituationEnum,
  FieldCompletionName,
} from '../../../@types/graphql';
import { SettingsContext } from 'contexts/settings/SettingsContext';
import { UserContext } from 'contexts/user/UserContext';
import ProfessionalSituationPanel from './ProfessionalSituationPanel';
import StudentSituationPanel from './StudentSituationPanel';
import SelectInput from 'components/select-input/SelectInput';
import OtherSituationPanel from './OtherSituationPanel';

export default function SituationPanel() {
  const { user } = useContext(UserContext);
  const [primarySituation, setPrimarySituation] =
    useState<PrimarySituationEnum>(
      user?.primarySituation?.key || PrimarySituationEnum.Other,
    );

  const settingsContext = useContext(SettingsContext);
  const situations: PrimarySituation[] = useMemo(() => {
    if (!settingsContext?.settings) {
      return [];
    }

    return settingsContext.settings.primarySituations;
  }, [settingsContext?.settings]);

  // eslint-disable-next-line react/display-name
  const ComplementarySituationPanel = useMemo(
    () =>
      function () {
        if (primarySituation === PrimarySituationEnum.Worker) {
          return (
            <ProfessionalSituationPanel primarySituation={primarySituation} />
          );
        } else if (primarySituation === PrimarySituationEnum.Student) {
          return <StudentSituationPanel primarySituationProps={primarySituation} />;
        }

        return <OtherSituationPanel primarySituation={primarySituation} />;
      },
    [primarySituation],
  );

  return (
    <>
      <form>
        <SelectInput
          key={primarySituation}
          name="primarySituation"
          disabled={
            !!user?.editionCompletionStatus?.fieldsCompletion?.find(
              (completionStatus) =>
                completionStatus.name == FieldCompletionName.PrimarySituation &&
                completionStatus.disabled
            )
          }
          values={situations.map(({ key, name }) => ({
            value: key,
            translation: name,
          }))}
          initialValue={(function () {
            const situation = situations.find(
              (situation) => situation.key === primarySituation,
            );
            return (
              situation && {
                value: situation!.key,
                translation: situation!.name,
              }
            );
          })()}
          onChange={(key) => {
            setPrimarySituation(key);
          }}
        />

        {ComplementarySituationPanel && <ComplementarySituationPanel />}
      </form>
    </>
  );
}
