import { useMutation } from '@apollo/client';
import { useSnackbar } from '@myjobglasses/account-ui';
import { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Confirm_EmailMutation,
  Confirm_EmailMutationVariables,
} from '../../../@types/graphql';
import PageLoaderContext from '../../../contexts/pageLoader';
import Routes from '../../../Routes';
import translations from './ConfirmEmail.translations';
import { CONFIRM_EMAIL } from './ConfirmEmail.gql';
import { useTokens } from '../../../hooks/use-tokens/useTokens';

function ConfirmEmail() {
  const intl = useIntl();
  const navigate = useNavigate();
  const { success, error: errorSnackbar } = useSnackbar();
  const { setLoading } = useContext(PageLoaderContext);
  const location = useLocation();
  const { confirmationToken } = useTokens(location?.search);

  const [confirmEmail, { loading }] = useMutation<
    Confirm_EmailMutation,
    Confirm_EmailMutationVariables
  >(CONFIRM_EMAIL, {
    variables: {
      confirmationToken: confirmationToken || '',
    },
  });

  useEffect(() => {
    confirmEmail()
      .then(() => {
        success(intl.formatMessage(translations.success), {
          icon: 'check_circle',
        });
        navigate(Routes.home);
      })
      .catch(() => {
        errorSnackbar(intl.formatMessage(translations.error));
        navigate(Routes.home);
      });
  }, []);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  return null;
}

export default ConfirmEmail;
