import { IntlShape } from 'react-intl';
import { object, string } from 'yup';
import { GouvSchoolCreateModalTranslations } from './GouvSchoolCreateModal.translations';

export const gouvSchoolCreateModalValidationSchema = (intl: IntlShape) =>
  object({
    name: string().required(
      intl.formatMessage(GouvSchoolCreateModalTranslations.nameRequired)
    ),
    communeName: string().required(
			intl.formatMessage(GouvSchoolCreateModalTranslations.cityRequired)
		),
  });
