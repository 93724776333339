/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useFormikContext } from 'formik';
import PasswordStrength from './components/password-strength/PasswordStrength';
import MandatoryPasswordSpecs from './components/mandatory-password-specs/MandatoryPasswordSpecs';

import styles from './CheckPassword.module.scss';

interface CheckPasswordProps {
  name: string;
}

function CheckPassword<T extends { [key: string]: any }>({
  name,
}: CheckPasswordProps) {
  const { values, touched } = useFormikContext<T>();
  const password = values?.[name];
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const doCheck = !!touched?.[name] || password.length > 0;

  return (
    <div className={styles.CheckPasswordContainer}>
      <PasswordStrength
        password={password}
        doCheck={doCheck}
      />
      <MandatoryPasswordSpecs password={password} />
    </div>
  );
}

export default CheckPassword;
