import { useCallback, useContext } from 'react';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import { Language } from '../../../@types/graphql';
import { LanguagesPanelTranslations } from './panels.translations';
import { LanguagesTranslations } from 'i18n/languages.translations';
import StaticAutocompleteMultiSelectInput from '../../../components/static-autocomplete-multi-select-input/StaticAutocompleteMultiSelectInput';
import useUserContextUpdate from 'contexts/user/UserContextUpdate.hook';
import { UserContext } from 'contexts/user/UserContext';
import SaveIcon from 'assets/component-icons/SaveIcon';
import { CommonTranslations } from 'i18n/common.translations';
import SecondaryButton from 'components/secondary-button/SecondaryButton';
import RefreshIcon from 'assets/component-icons/RefreshIcon';
import RemovableTag from 'components/removable-tag/RemovableTag';

type FormikValues = {
  languages: Language[];
};

export default function LanguagesPanel() {
  const intl = useIntl();

  const { user } = useContext(UserContext);
  const updateUser = useUserContextUpdate();

  const formik = useFormik<FormikValues>({
    initialValues: {
      languages: user?.languages || [Language.French],
    },
    onSubmit: ({ languages }) => {
      updateUser({ languages: languages });
    },
  });

  const addLanguage = useCallback(
    (language: Language) => {
      if (!formik.values.languages.includes(language)) {
        formik.setFieldValue('languages', [
          ...formik.values.languages,
          language,
        ]);
      }
    },
    [formik.values.languages],
  );

  const removeLanguage = useCallback(
    (language: Language) => {
      const newArray = formik.values.languages.filter(
        (lang) => lang !== language,
      );
      formik.setFieldValue('languages', newArray);
    },
    [formik.values.languages],
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <StaticAutocompleteMultiSelectInput
        name="language"
        label={intl.formatMessage(LanguagesPanelTranslations.languages)}
        values={Object.values(Language).map((value) => ({
          value,
          translation: intl.formatMessage(LanguagesTranslations[value]),
        }))}
        selectedValues={formik.values.languages}
        onChange={addLanguage}
        sort
      />

      <div className="languages-panel__languages">
        {formik.values.languages.map((lang) => (
          <RemovableTag
            key={lang}
            value={lang}
            translation={LanguagesTranslations[lang]}
            onRemove={removeLanguage}
          />
        ))}
      </div>

      <footer>
        <PrimaryButton
          label={intl.formatMessage(CommonTranslations.save)}
          icon={SaveIcon}
          submit
          accent
          leftIcon
          disabled={!formik.isValid}
        />
        <SecondaryButton
          label={intl.formatMessage(CommonTranslations.reset)}
          leftIcon={RefreshIcon}
          onClick={() => formik.resetForm()}
          iconButtonWithText
        />
      </footer>
    </form>
  );
}
