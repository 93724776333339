import CheckIcon from 'assets/component-icons/CheckIcon';
import { IconProps } from 'assets/component-icons/Icon';
import LoadingIcon from 'assets/component-icons/LoadingIcon';
import SaveIcon from 'assets/component-icons/SaveIcon';
import { ComponentType, useCallback, useState } from 'react';

export type FormikNetworkState = 'idle' | 'loading' | 'succeeded' | 'error';

function getIcon(
  networkState: FormikNetworkState,
  defaultIcon: ComponentType<IconProps> | null,
) {
  switch (networkState) {
    case 'loading':
      return LoadingIcon;
    case 'succeeded':
      return CheckIcon;
    default:
      return defaultIcon;
  }
}

export default function useFormikNetworkState(
  defaultIcon: ComponentType<IconProps> | null = SaveIcon,
) {
  const [networkState, setNetworkState] = useState<FormikNetworkState>('idle');

  const setSucceeded = useCallback(() => {
    setNetworkState('succeeded');
    setTimeout(() => {
      setNetworkState('idle');
    }, 2000);
  }, [networkState]);

  return {
    state: networkState,
    iconBasedOnNetworkState: getIcon(networkState, defaultIcon),
    loading: () => setNetworkState('loading'),
    error: () => setNetworkState('idle'),
    succeeded: () => setSucceeded(),
  };
}
