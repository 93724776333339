import classNames from 'classnames';
import { CSSProperties, PropsWithChildren } from 'react';
import './ButtonsGroup.scss';

type ButtonsGroupsProps = {
  style?: CSSProperties;
  className?: string;
};

export default function ButtonsGroup({
  children,
  className,
  style,
}: PropsWithChildren<ButtonsGroupsProps>) {
  return (
    <div
      className={classNames('buttons-group', className)}
      style={style}
    >
      {children}
    </div>
  );
}
