import ArrowRightIcon from 'assets/component-icons/ArrowRightIcon';
import LockIcon from 'assets/component-icons/LockIcon';
import { useFormik } from 'formik';
import useFormikNetworkState from 'hooks/use-formik-network-state';
import { CommonTranslations } from 'i18n/common.translations';
import { useIntl } from 'react-intl';
import formikError from '../../../components/formik-helper/formik.helper';
import PasswordInput from '../../../components/password-input/PasswordInput';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import useProfileCompletionUserUpdate from './ProfileCompletionUserUpdate.hook';
import { PasswordCreationPanelTranslations } from './panels.translations';
import { passwordCreationValidationSchema } from './panels.validation';

type FormikValues = {
  password: string;
  confirmPassword: string;
};

export default function PasswordCreationPanel() {
  const intl = useIntl();
  const updateUser = useProfileCompletionUserUpdate();
  const networkState = useFormikNetworkState(ArrowRightIcon);

  const formik = useFormik<FormikValues>({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: passwordCreationValidationSchema(intl),
    validateOnMount: true,
    onSubmit: async ({ password }) => {
      networkState.loading();
      await updateUser({ password });
      networkState.succeeded();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <PasswordInput
        dataCy="password"
        strengthHints
        name="password"
        label={intl.formatMessage(PasswordCreationPanelTranslations.password)}
        placeholder="••••••••••••"
        icon={LockIcon}
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formikError(formik.touched, formik.errors, 'password')}
      />

      <PasswordInput
        dataCy="confirm-password"
        name="confirmPassword"
        label={intl.formatMessage(
          PasswordCreationPanelTranslations.passwordConfirmation,
        )}
        placeholder="••••••••••••"
        icon={LockIcon}
        value={formik.values.confirmPassword}
        confirm
        passwordToConfirm={formik.values.password}
        onChange={formik.handleChange}
        error={formikError(formik.touched, formik.errors, 'confirmPassword')}
      />

      <PrimaryButton
        label={intl.formatMessage(CommonTranslations.continue)}
        icon={networkState.iconBasedOnNetworkState}
        submit
        disabled={!formik.isValid}
      />
    </form>
  );
}
