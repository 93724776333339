import { BinaryResponse } from 'models/BinaryResponse.enum';
import { IntlShape } from 'react-intl';
import { array, boolean, date, mixed, number, object, ref, string } from 'yup';
import {
  DiplomaEnum,
  GenderEnum,
  GradeEnum,
  MeetingPreference,
} from '../../../@types/graphql';
import { PanelErrorTranslations } from './panels.translations';

export const birthdateValidationSchema = (intl: IntlShape) =>
  object({
    birthdate: date()
      // eslint-disable-next-line max-len
      .required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
  });

// TODO: Add strong password validation
export const passwordCreationValidationSchema = (intl: IntlShape) =>
  object({
    password: string().required(
      intl.formatMessage(PanelErrorTranslations.inputEmpty),
    ),
    confirmPassword: string()
      .required(intl.formatMessage(PanelErrorTranslations.inputEmpty))
      .test(
        'passwords-match',
        intl.formatMessage(PanelErrorTranslations.passwordMismatch),
        function (value) {
          return this.parent.password === value;
        },
      ),
  });

export const identityPanelValidationSchema = (intl: IntlShape) =>
  object({
    gender: mixed<GenderEnum>().required(
      intl.formatMessage(PanelErrorTranslations.inputEmpty),
    ),
    firstName: string()
      .required(intl.formatMessage(PanelErrorTranslations.inputEmpty))
      .min(2, intl.formatMessage(PanelErrorTranslations.inputTooShort)),
    lastName: string()
      .required(intl.formatMessage(PanelErrorTranslations.inputEmpty))
      .min(1, intl.formatMessage(PanelErrorTranslations.inputTooShort)),
  });

export const professionalSituationPanelValidationSchema = (intl: IntlShape) =>
  object({
    position: string().required(
      intl.formatMessage(PanelErrorTranslations.inputEmpty),
    ),
    companyName: string().required(
      intl.formatMessage(PanelErrorTranslations.inputEmpty),
    ),
    yearsOfExperience: number().required(
      intl.formatMessage(PanelErrorTranslations.inputEmpty),
    ),
  });

export const otherSituationPanelValidationSchema = (intl: IntlShape) =>
  object({
    neverWorked: boolean(),
    previousPosition: string().when('neverWorked', {
      is: false,
      then: (schema) =>
        schema.required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
      otherwise: (schema) => schema,
    }),
    previousCompanyName: string().when('neverWorked', {
      is: false,
      then: (schema) =>
        schema.required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
      otherwise: (schema) => schema,
    }),
    previousPositionYearsOfExperiences: number().when('neverWorked', {
      is: false,
      then: (schema) =>
        schema.required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
      otherwise: (schema) => schema,
    }),
  });

export const currentEducationPanelValidationSchema = (intl: IntlShape) =>
  object({
    currentGrade: mixed<GradeEnum>().required(
      intl.formatMessage(PanelErrorTranslations.inputEmpty),
    ),
    currentSchoolName: string().required(
      intl.formatMessage(PanelErrorTranslations.inputEmpty),
    ),
    currentSchoolCity: string().required(
      intl.formatMessage(PanelErrorTranslations.inputEmpty),
    ),
  });

export const programAffiliationPanelValidationSchema = (intl: IntlShape) =>
  object({
    followingVocationProgram: mixed<BinaryResponse>().required(
      intl.formatMessage(PanelErrorTranslations.inputEmpty),
    ),
    programAffiliationCode: string().when('followingVocationProgram', {
      is: BinaryResponse.Yes,
      then: (schema) =>
        schema
          .required(intl.formatMessage(PanelErrorTranslations.inputEmpty))
          .min(4, intl.formatMessage(PanelErrorTranslations.inputTooShort)),
      otherwise: (schema) => schema,
    }),
  });

export const maxMeetingPanelValidationSchema = (intl: IntlShape) =>
  object({
    maxMeetingsPerMonth: number(),
  });

const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;
export const phonePanelValidationSchema = (intl: IntlShape) =>
  object({
    phoneNumber: string()
      .required(intl.formatMessage(PanelErrorTranslations.inputEmpty))
      .matches(
        phoneRegExp,
        intl.formatMessage(PanelErrorTranslations.invalidPhoneNumber),
      ),
  });

export const languagesPanelValidationSchema = (intl: IntlShape) =>
  object({
    languages: array()
      .of(string())
      .min(1, intl.formatMessage(PanelErrorTranslations.inputEmpty))
      .required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
  });

export const meetingPlacePanelValidationSchema = (intl: IntlShape) =>
  object({
    googlePlace: object().required(
      intl.formatMessage(PanelErrorTranslations.inputEmpty),
    ),
  });

export const meetingPreferencesPanelValidationSchema = (intl: IntlShape) =>
  object({
    meetingPreferences: array()
      .test(
        'at-least-one-checked',
        intl.formatMessage(PanelErrorTranslations.inputEmpty),
        (values: MeetingPreference[] = []) =>
          values.some((preference) => preference.value === true),
      )
      .required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
  });

export const avatarPanelValidationSchema = (intl: IntlShape) =>
  object({
    avatar: mixed().required(
      intl.formatMessage(PanelErrorTranslations.inputEmpty),
    ),
  });

export const memberPresentationValidationSchema = (intl: IntlShape) =>
  object({
    memberPresentation: string().required(
      intl.formatMessage(PanelErrorTranslations.inputEmpty),
    ),
  });

export const ambassadorPresentationValidationSchema = (intl: IntlShape) =>
  object({
    ambassadorPresentation: string().required(
      intl.formatMessage(PanelErrorTranslations.inputEmpty),
    ),
  });

export const aspirationPresentationValidationSchema = (intl: IntlShape) =>
  object({
    aspirationPresentation: string().required(
      intl.formatMessage(PanelErrorTranslations.inputEmpty),
    ),
  });

export const expertisePresentationValidationSchema = (intl: IntlShape) =>
  object({
    expertisePresentation: string().required(
      intl.formatMessage(PanelErrorTranslations.inputEmpty),
    ),
  });

export const interestsPresentationValidationSchema = (intl: IntlShape) =>
  object({
    interestsPresentation: string().required(
      intl.formatMessage(PanelErrorTranslations.inputEmpty),
    ),
  });

export const pastEducationPanelValidationSchema = (intl: IntlShape) =>
  object({
    acquiredDiplomaLevel: mixed<DiplomaEnum>().required(
      intl.formatMessage(PanelErrorTranslations.inputEmpty),
    ),
    acquiredDiplomaTitle: string().when('acquiredDiplomaLevel', {
      is: (AcquiredDiplomaLevel: DiplomaEnum) =>
        AcquiredDiplomaLevel !== DiplomaEnum.None &&
        AcquiredDiplomaLevel !== DiplomaEnum.Cep &&
        AcquiredDiplomaLevel !== DiplomaEnum.Brevet,
      then: (schema) =>
        schema.required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
      otherwise: (schema) => schema,
    }),
    acquiredDiplomaSchoolName: string().when('acquiredDiplomaLevel', {
      is: (AcquiredDiplomaLevel: DiplomaEnum) =>
        AcquiredDiplomaLevel !== DiplomaEnum.None,
      then: (schema) =>
        schema.required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
      otherwise: (schema) => schema,
    }),
    startDate: number().when('acquiredDiplomaLevel', {
      is: (AcquiredDiplomaLevel: DiplomaEnum) =>
        AcquiredDiplomaLevel !== DiplomaEnum.None,
      then: (schema) =>
        schema
          .min(1, intl.formatMessage(PanelErrorTranslations.inputEmpty))
          .max(
            new Date().getFullYear(),
            intl.formatMessage(PanelErrorTranslations.endDateInFuture),
          ),
      otherwise: (schema) => schema,
    }),
    endDate: number().when('acquiredDiplomaLevel', {
      is: (AcquiredDiplomaLevel: DiplomaEnum) =>
        AcquiredDiplomaLevel !== DiplomaEnum.None,
      then: (schema) =>
        schema
          .min(
            ref('startDate'),
            intl.formatMessage(PanelErrorTranslations.endDateBeforeStartDate),
          )
          .min(1, intl.formatMessage(PanelErrorTranslations.inputEmpty))
          .max(
            new Date().getFullYear(),
            intl.formatMessage(PanelErrorTranslations.endDateInFuture),
          ),
      otherwise: (schema) => schema,
    }),
  });
