import { defineMessages } from 'react-intl';

export const SegmentationTranslations = defineMessages({
  title: {
    id: 'Segmentation.title',
    defaultMessage: 'Bienvenue !',
  },
  subtitle: {
    id: 'Segmentation.subtitle',
    defaultMessage: 'À quelle interface souhaitez-vous accéder ?',
  },

  hrAdminTitle: {
    id: 'Segmentation.hrAdminTitle',
    defaultMessage: 'Espace Admin RH',
  },
  hrAdminDescription: {
    id: 'Segmentation.hrAdminDescription',
    defaultMessage:
      'Afin de gérer le programme Ambassadeurs de votre entreprise.',
  },
  mjgTitle: {
    id: 'Segmentation.mjgTitle',
    defaultMessage: 'My Job Glasses',
  },
  mjgDescription: {
    id: 'Segmentation.mjgDescription',
    defaultMessage:
      "Afin d'utiliser la plateforme en tant que Membre ou Ambassadeur.",
  },
});
