import { useSnackbar } from '@myjobglasses/account-ui';
import { useIntl } from 'react-intl';
import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { EMAIL_FROM_INVITATION } from '../gqls/ExtractInfosFromInvitationToken.gql';
import {
  ExtractInfosFromInvitationMutationErrorEnum,
  EmailFromInvitationMutation,
  EmailFromInvitationMutationVariables,
} from '../../../@types/graphql';
import { InvitationTranslations } from '../ConfirmSignup.translations';

export default function useEmailFromInvitationToken({ invitationToken }: { invitationToken?: string }) {
  const intl = useIntl();
  const snackbar = useSnackbar();

  const [emailFromInvitationMutation, { data, loading: emailFromInvitationLoading, error }] = useMutation<
    EmailFromInvitationMutation,
    EmailFromInvitationMutationVariables
  >(EMAIL_FROM_INVITATION, {
    onError: (error) => {
      const errorTokens = error.graphQLErrors.map( ({ extensions }) => extensions?.code);

      if (errorTokens.includes(ExtractInfosFromInvitationMutationErrorEnum.Invalid))
        snackbar.error(intl.formatMessage(InvitationTranslations.invitationTokenInvalid));
      if (errorTokens.includes(ExtractInfosFromInvitationMutationErrorEnum.AlreadyClaimed))
        snackbar.error(intl.formatMessage(InvitationTranslations.invitationTokenAlreadyUsed));
    },
  });

  useEffect(() => {
    if (!invitationToken || !invitationToken.length) { return; }

    emailFromInvitationMutation({
      variables: { invitationToken: invitationToken },
    });
  }, [invitationToken]);

  return [
    data?.extractInfosFromInvitation.email,
    { loading: emailFromInvitationLoading, error: error },
  ];
}
