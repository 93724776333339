import { object, boolean, date, mixed, string } from 'yup';
import { IntlShape } from 'react-intl';
import { AuthenticationErrorTranslations } from 'scenes/authentication/Authentication.translations';
import { hasMinimumAge } from './utils/check-date.util';
import { ComesFromEnum } from '../../@types/graphql';
import { PanelErrorTranslations } from 'scenes/profile-completion/panels/panels.translations';

export const signupValidationSchema = (intl: IntlShape) =>
  object({
    email: string()
          .required(intl.formatMessage(AuthenticationErrorTranslations.emailRequired)),
    birthdate: date()
      // eslint-disable-next-line max-len
      .required(
        intl.formatMessage(AuthenticationErrorTranslations.birthdateRequired),
      )
      .test({
        name: 'age specs',
        exclusive: true,
        test: (value) => hasMinimumAge(value),
        message: intl.formatMessage(
          AuthenticationErrorTranslations.minimumAgeRequired,
        ),
      }),
    cgu: boolean().oneOf(
      [true],
      intl.formatMessage(AuthenticationErrorTranslations.mustAcceptCGU),
    ),
    comesFrom: mixed<ComesFromEnum>().required(
      intl.formatMessage(PanelErrorTranslations.inputEmpty),
    ),
  });
