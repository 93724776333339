import { CompletionProperties, CookieUtmsType } from './ahoy.model';
import { setCookie, getCookie } from './cookies';

const config = {
  platform: 'Web',
  visitsUrl: window.__RUNTIME_CONFIG__.REACT_APP_AHOY_VISITS_ENDPOINT || '/ahoy/visits',
  eventsUrl: window.__RUNTIME_CONFIG__.REACT_APP_AHOY_EVENTS_ENDPOINT || '/ahoy/events',
  cookieDomain: window.__RUNTIME_CONFIG__.REACT_APP_COOKIE_DOMAIN || '',
};

const ahoy = window.ahoy;
ahoy.configure({
  visitsUrl: config.visitsUrl,
  eventsUrl: config.eventsUrl,
  cookieDomain: config.cookieDomain,
});

// /* eslint-disable no-bitwise */
// http://stackoverflow.com/a/2117523/1177228
function generateId(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function googleAnalyticsCookie(): CookieUtmsType | null {
  const cookieUtms = getCookie('__cookie_utms');
  if (!cookieUtms) {
    return null;
  }

  try {
    const parsedCookie = JSON.parse(cookieUtms) as CookieUtmsType;
    return parsedCookie;
  } catch (_err) {
    return null;
  }
}

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/require-await
export async function trackStartCompletion(
  completionProperties: CompletionProperties,
) {
  const visitId = getCookie('ahoy_visit');
  const visitorId = getCookie('ahoy_visitor');

  let widgetCookie = getCookie('widget_source');
  if (widgetCookie && widgetCookie[0] !== '{') {
    widgetCookie = decodeURIComponent(widgetCookie);
  }
  const parsedWidgetCookie = JSON.parse(widgetCookie as string);

  const eventProperties = {
    visit_token: visitId,
    visitor_token: visitorId,
    completionProperties,
    tracking_elements: {
      widget_source_id: '',
      widget_source_id_added_at: '',
    },
  };

  if (parsedWidgetCookie) {
    eventProperties.tracking_elements.widget_source_id =
      parsedWidgetCookie.widget_source_id;
    eventProperties.tracking_elements.widget_source_id_added_at =
      parsedWidgetCookie.widget_source_id_added_at;
  }
  ahoy.track('completion_step_started', eventProperties);
}

export async function trackFinishCompletion(
  completionProperties: CompletionProperties,
) {
  const visitId = getCookie('ahoy_visit');
  const visitorId = getCookie('ahoy_visitor');

  let widgetCookie = getCookie('widget_source');
  if (widgetCookie && widgetCookie[0] !== '{') {
    widgetCookie = decodeURIComponent(widgetCookie);
  }
  const parsedWidgetCookie = JSON.parse(widgetCookie as string);

  const eventProperties = {
    visit_token: visitId,
    visitor_token: visitorId,
    completionProperties,
    tracking_elements: {
      widget_source_id: '',
      widget_source_id_added_at: '',
    },
  };

  if (parsedWidgetCookie) {
    eventProperties.tracking_elements.widget_source_id =
      parsedWidgetCookie.widget_source_id;
    eventProperties.tracking_elements.widget_source_id_added_at =
      parsedWidgetCookie.widget_source_id_added_at;
  }
  ahoy.track('completion_step_finished', eventProperties);
}
