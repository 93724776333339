import UserIcon from 'assets/component-icons/UserIcon';
import PrimaryButton from 'components/primary-button/PrimaryButton';
import { UserContext } from 'contexts/user/UserContext';
import { useContext, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { SidebarTranslations } from '../sidebar.translations';
import {
  RequestModerationMutation,
  RequestModerationMutationVariables,
  UnpublishMutation,
  UnpublishMutationVariables,
} from '../../../../../@types/graphql';
import MinusIcon from 'assets/component-icons/MinusIcon';
import { useMutation } from '@apollo/client';
import {
  REQUEST_MODERATION_MUTATION,
  UNPUBLISH_MUTATION,
} from 'gqls/Publication.gql';
import useModal from 'components/modal/Modal.hook';
import { PaidAmbassadorUnpublicationModalTranslations } from '../paid-ambassador-unpublication-modal/PaidAmbassadorUnpublicationModal.translations';
import PaidAmbassadorUnpublicationModal from '../paid-ambassador-unpublication-modal/PaidAmbassadorUnpublicationModal';
import { FreeAmbassadorUnpublicationModalTranslations } from '../free-ambassador-unpublication-modal/FreeAmbassadorUnpublicationModal.translations';
import FreeAmbassadorUnpublicationModal from '../free-ambassador-unpublication-modal/FreeAmbassadorUnpublicationModal';

export default function PublicationButton() {
  const { formatMessage } = useIntl();
  const { user } = useContext(UserContext);
  const [openModal, closeModal] = useModal();
  const [buttonState, setButtonState] = useState({
    icon: UserIcon,
    label: '',
    disabled: false,
  });

  if (user?.currentUserAuthorizations.canPublish && user?.currentUserAuthorizations.canUnpublish) return null;

  const [requestModeration] = useMutation<
    RequestModerationMutation,
    RequestModerationMutationVariables
  >(REQUEST_MODERATION_MUTATION, {
    refetchQueries: ['GetUser'],
    variables: { userId: user?.id || '' },
    onCompleted: () => {
      setButtonState({
        icon: UserIcon,
        label: formatMessage(SidebarTranslations.ongoingModeration),
        disabled: true,
      });
    },
  });

  const [requestUnpublication] = useMutation<
    UnpublishMutation,
    UnpublishMutationVariables
  >(UNPUBLISH_MUTATION, {
    refetchQueries: ['GetUser'],
    variables: { userId: user?.id || '' },
    onCompleted: () => {
      setButtonState({
        icon: UserIcon,
        label: formatMessage(SidebarTranslations.requestModeration),
        disabled: false,
      });
    },
  });

  const handleUnpublishProfile = () => {
    requestUnpublication();
    closeModal();
  };

  useEffect(() => {
    if (!user) return;

    let newButtonState = { icon: UserIcon, label: '', disabled: false };

    switch (true) {
      case user.isModeratable &&
        user.currentUserAuthorizations.canRequestModeration:
        newButtonState = {
          icon: UserIcon,
          label: formatMessage(SidebarTranslations.requestModeration),
          disabled: false,
        };
        break;
      case user.isInModeration:
        newButtonState = {
          icon: UserIcon,
          label: formatMessage(SidebarTranslations.ongoingModeration),
          disabled: true,
        };
        break;
      case !user.isPaidAmbassador &&
        user.isUnpublishable &&
        user.currentUserAuthorizations.canUnpublish:
        newButtonState = {
          icon: MinusIcon,
          label: formatMessage(SidebarTranslations.unrequestPublication),
          disabled: false,
        };
        break;
      case user.isPaidAmbassador &&
        user.isUnpublishable &&
        !user.currentUserAuthorizations.canUnpublish &&
        user.currentUserAuthorizations.canRequestAdminRhUnpublication:
        newButtonState = {
          icon: MinusIcon,
          label: formatMessage(SidebarTranslations.unrequestPublication),
          disabled: false,
        };
        break;
      default:
        newButtonState = {
          icon: UserIcon,
          label: formatMessage(SidebarTranslations.uncompletedProfile),
          disabled: true,
        };
        break;
    }

    setButtonState(newButtonState);
  }, [user, formatMessage]);

  if (!user) return null;

  const getModalContent = () => {
    if (!user.isPaidAmbassador) {
      return (
        <FreeAmbassadorUnpublicationModal
          handleUnpublishProfile={handleUnpublishProfile}
          handleCloseModal={closeModal}
        />
      );
    }
    return (
      <PaidAmbassadorUnpublicationModal
        mainAdminEmail={user.mainAdminEmail || ''}
      />
    );
  };

  return (
    <PrimaryButton
      leftIcon
      icon={buttonState.icon}
      label={buttonState.label}
      disabled={buttonState.disabled}
      onClick={() => {
        if (
          user.isModeratable &&
          user.currentUserAuthorizations.canRequestModeration
        ) {
          requestModeration();
        } else if (!user.isModeratable && user.isUnpublishable) {
          openModal({
            title: formatMessage(
              user.isPaidAmbassador
                ? PaidAmbassadorUnpublicationModalTranslations.title
                : FreeAmbassadorUnpublicationModalTranslations.title,
            ),
            content: getModalContent(),
          });
        }
      }}
    />
  );
}
