import CheckIcon from 'assets/component-icons/CheckIcon';
import { IconProps } from 'assets/component-icons/Icon';
import classNames from 'classnames';
import useDebounce from 'hooks/use-debounce';
import { ComponentType, useEffect, useRef, useState } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import './GooglePlaceInput.scss';

type PlacePrediction = {
  description: string;
  place_id: string;
};

type GetPlacePrediction = ({ input }: { input: string }) => void;

type GooglePlaceInputProps = {
  icon?: ComponentType<IconProps>;
  name: string;
  label?: string;
  placeholder?: string;
  initialValue?: string;
  onChange: (placePrediction: PlacePrediction) => void;
  error?: string;
  sort?: boolean;
  city?: boolean;
  loading?: boolean;
};

export default function GooglePlaceInput({
  icon: Icon,
  name,
  label,
  placeholder,
  error,
  onChange,
  initialValue,
  city,
}: GooglePlaceInputProps) {
  const componentRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState(initialValue || '');
  const [debouncedValue] = useDebounce(value);
  const [selectedIndex, setSelectedIndex] = useState<number>();

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const {
    placePredictions,
    getPlacePredictions,
  }: {
    placePredictions: PlacePrediction[];
    getPlacePredictions: GetPlacePrediction;
  } = usePlacesService({
    options: {
      types: city && ['locality'],
    },
  });

  function handleComponentClick() {
    setDropdownOpen((prev) => {
      const mirrorValue = !prev;

      if (mirrorValue === true) {
        inputRef.current?.focus();
      } else {
        inputRef.current?.blur();
      }

      return mirrorValue;
    });
  }

  function handleSelection(v: PlacePrediction) {
    onChange(v);
    setValue(v.description);
    handleComponentClick();
  }

  useEffect(() => {
    if (debouncedValue) getPlacePredictions({ input: debouncedValue });
  }, [debouncedValue]);

  useEffect(() => {
    document.addEventListener('mousedown', (ev) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(ev.target as Node)
      ) {
        setDropdownOpen(false);
      }
    });

    document.addEventListener('mousemove', () => {
      if (selectedIndex) {
        setSelectedIndex(undefined);
      }
    });

    document.addEventListener('keydown', (ev) => {
      if (isDropdownOpen) {
        switch (ev.key) {
          case 'Escape':
            ev.preventDefault();
            setDropdownOpen(false);
            break;
          case 'Enter':
            ev.preventDefault();
            if (placePredictions.length > 0) {
              handleSelection(placePredictions[0]);
              setDropdownOpen(false);
            }
            break;
        }
      }
    });
  }, [isDropdownOpen, placePredictions]);

  return (
    <div
      ref={componentRef}
      className="select-input"
    >
      {label && (
        <label
          htmlFor={name}
          className={classNames({ error: error })}
        >
          {label}
        </label>
      )}

      <div
        data-cy="google-place"
        className={classNames(
          'select-input__main',
          { 'select-input__main--focused': isDropdownOpen },
          { 'select-input__main--error': error },
        )}
        onClick={handleComponentClick}
      >
        {Icon && <Icon className="select-input__main__icon" />}

        <input
          data-cy="google-place__input"
          ref={inputRef}
          value={value}
          onChange={({ target: { value: v } }) => {
            setValue(v);
          }}
          placeholder={placeholder}
        />
      </div>

      {isDropdownOpen && placePredictions.length > 0 && (
        <ul className="select-input__dropdown">
          {placePredictions.map((v) => (
            <li
              data-cy="google-place__item"
              key={v.description}
              className={classNames('select-input__dropdown__option')}
            >
              <button
                type="button"
                onClick={() => handleSelection(v)}
              >
                <CheckIcon />
                {v.description}
              </button>
            </li>
          ))}
        </ul>
      )}

      {error && <span className="select-input__error">{error}</span>}
    </div>
  );
}
