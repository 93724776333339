/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Icon } from '@myjobglasses/account-ui';
import { useIntl } from 'react-intl';
import { Navigate, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import Routes from '../../../../Routes';
import RTFBold from '../../../commons/rich-text-formatting/bold/RTFBold';
import RTFBreakline from '../../../commons/rich-text-formatting/breakline/RTFBreakline';
import { useRequestNewPassword } from '../../hooks/useRequestNewPassword';
import translations from './ConfirmNewPassword.translations';
import validation from './ConfirmNewPassword.validation';

import styles from './ConfirmNewPassword.module.scss';

function ConfirmNewPassword() {
  const intl = useIntl();
  const location = useLocation();

  const onSubmit = useRequestNewPassword();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (!location?.state?.email) {
    return <Navigate to={Routes.requestNewPassword} />;
  }

  return (
    <div className={styles.confirmNewPasswordView}>
      <div className={styles.confirmNewPassword}>
        <div className={styles.iconHeader}>
          <Icon
            name="lock_open"
            className={styles.icon}
          />
        </div>
        <h1 className={styles.title}>
          {intl.formatMessage(translations.title)}
        </h1>
        <p className={styles.description}>
          {intl.formatMessage(translations.description, {
            email: location.state.email,
            boldThis: RTFBold,
            breakline: RTFBreakline,
          })}
        </p>
        <Formik
          initialValues={{
            email: location.state.email,
          }}
          validateOnMount
          validateOnChange
          validationSchema={validation}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {({
            isValid,
            handleSubmit,
            // eslint-disable-next-line arrow-body-style
          }) => {
            return (
              <form
                className={styles.form}
                onSubmit={handleSubmit}
              >
                <button
                  type="submit"
                  data-testid="submit-button"
                  className={styles.submitButton}
                  disabled={!isValid}
                >
                  {intl.formatMessage(translations.resendEmailButton)}
                </button>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default ConfirmNewPassword;
