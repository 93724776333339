import { FormikHelpers } from 'formik';
import {
  MJGApolloError,
  MJGGraphQLErrorCode,
  MJGValidationError,
} from '../@types/graphql-errors';

interface handleGraphqlErrorsOnFormikConfiguration<
  FormikField extends { [key: string]: any },
  GraphQLInputFields extends { [key: string]: any },
  ERRORS extends string | number,
> {
  fieldConnection?: {
    [fieldName in keyof GraphQLInputFields]?: keyof FormikField;
  };
  translations: {
    default: string;
    unknown: string;
    network: string;
  } & {
    [fieldName in keyof FormikField | 'global']?: {
      [errorCode in ERRORS | MJGGraphQLErrorCode]?: string;
    };
  };
}

export function handleGraphqlErrorsOnFormik<
  FormikField extends { [key: string]: any },
  GraphQLInputFields extends { [key: string]: any },
  ERRORS extends string | number,
>(
  apolloError: MJGApolloError<GraphQLInputFields, ERRORS>,
  formikBag: FormikHelpers<FormikField>,
  configuration: handleGraphqlErrorsOnFormikConfiguration<
    FormikField,
    GraphQLInputFields,
    ERRORS
  >,
) {
  // 1. identify all fields errors
  const errors: { [fieldName in keyof FormikField]?: string } = {};
  apolloError.graphQLErrors.forEach((error) => {
    if (error.extensions.code === MJGGraphQLErrorCode.BAD_USER_INPUT) {
      const typedError = <MJGValidationError<GraphQLInputFields, ERRORS>>error;

      (
        Object.keys(typedError.extensions.validationErrors) as Array<
          keyof GraphQLInputFields
        >
      ).forEach((validationError) => {
        const firstError =
          typedError.extensions.validationErrors[validationError][0];
        const key = (configuration.fieldConnection?.[validationError] ||
          validationError) as keyof FormikField;
        if (!errors[key]) {
          errors[key] =
            configuration.translations[key]?.[firstError] ||
            configuration.translations.unknown;
        }
      });
    }
  });

  // 2. If fields errors are found
  //    - Set errors to the form
  //    - Stop the process
  if (Object.keys(errors).length) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    formikBag.setErrors(errors);
    return;
  }

  // 3. In network error case
  //    & Stop the process
  if (apolloError.networkError) {
    formikBag.setStatus({
      __globalError: configuration.translations.network,
    });
    return;
  }

  // 4. In last case,
  //    - Found a translation for the first error
  //    - Or set the default error
  const firstError = apolloError.graphQLErrors[0];
  const translationOfFirstError =
    configuration.translations.global?.[firstError.extensions.code];
  formikBag.setStatus({
    __globalError:
      translationOfFirstError || configuration.translations.default,
  });
}
