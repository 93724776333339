import { useMutation } from '@apollo/client';
import { useSnackbar } from '@myjobglasses/account-ui';
import { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import Layout, { LayoutHeader } from 'components/layout/Layout';
import {
  Confirm_Tutor_EmailMutation,
  Confirm_Tutor_EmailMutationVariables,
} from '../../../@types/graphql';
import PageLoaderContext from '../../../contexts/pageLoader';
import translations from './ConfirmTutorEmail.translations';
import { CONFIRM_TUTOR_EMAIL } from './ConfirmTutorEmail.gql';
import { useTokens } from '../../../hooks/use-tokens/useTokens';

export default function ConfirmTutorEmail() {
  const intl = useIntl();
  const { error: errorSnackbar } = useSnackbar();
  const { setLoading } = useContext(PageLoaderContext);
  const location = useLocation();
  const { confirmationToken } = useTokens(location?.search);

  const [confirmEmail, { loading }] = useMutation<
    Confirm_Tutor_EmailMutation,
    Confirm_Tutor_EmailMutationVariables
  >(CONFIRM_TUTOR_EMAIL, {
    variables: {
      confirmationToken: confirmationToken || '',
    },
  });

  useEffect(() => {
    confirmEmail().catch((err: Error) => {
      if (err.message === 'Already confirmed') {
        errorSnackbar(intl.formatMessage(translations.alreadyConfirmedError));
      } else {
        errorSnackbar(intl.formatMessage(translations.error));
      }
    });
  }, []);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  return (
    <Layout>
      <LayoutHeader>
        <h1>{intl.formatMessage(translations.title)}</h1>
        <p>
          {intl.formatMessage(translations.subtitle, {
            i: (text) => <i>{text}</i>,
            br: () => <br />,
          })}
        </p>
      </LayoutHeader>
    </Layout>
  );
}
