import { useLazyQuery } from '@apollo/client';
import { useSnackbar } from '@myjobglasses/account-ui';
import LockIcon from 'assets/component-icons/LockIcon';
import MailIcon from 'assets/component-icons/MailIcon';
import formikError from 'components/formik-helper/formik.helper';
import Layout, {
  LayoutHeader,
  LayoutSeparator,
} from 'components/layout/Layout';
import LinkedinButton from 'components/linkedin-button/LinkedinButton';
import PasswordInput from 'components/password-input/PasswordInput';
import PoleEmploiButton from 'components/pole-emploi-button/PoleEmploiButton';
import PrimaryButton from 'components/primary-button/PrimaryButton';
import TextInput from 'components/text-input/TextInput';
import { useFormik } from 'formik';
import useFormikNetworkState from 'hooks/use-formik-network-state';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import Routes from 'Routes';
import {
  CheckEmailAvailabilityQuery,
  CheckEmailAvailabilityQueryVariables,
} from '../../@types/graphql';
import useAuth from './Auth.hook';
import './Authentication.scss';
import {
  AuthenticationErrorTranslations,
  AuthenticationTranslations,
} from './Authentication.translations';
import authValidationSchema from './Authentication.validations';
import { CHECK_EMAIL_AVAILABILITY_QUERY } from './gql/CheckEmailAvailibity.gql';
import useOAuth from './oauth/OAuth.hook';

type FormikFields = {
  email: string;
  password?: string;
};

export default function Authentication() {
  const snackbar = useSnackbar();
  const intl = useIntl();

  const location = useLocation();
  const routeStateEmail: string | undefined = useMemo(() => {
    if (!location.state) return;
    snackbar.info(
      intl.formatMessage(AuthenticationErrorTranslations.putPassword),
    );
    return location.state?.email;
  }, [location.state?.email]);

  const auth = useAuth();
  const oauth = useOAuth();

  const [checkEmailAvailibity] = useLazyQuery<
    CheckEmailAvailabilityQuery,
    CheckEmailAvailabilityQueryVariables
  >(CHECK_EMAIL_AVAILABILITY_QUERY);

  const networkState = useFormikNetworkState(null);

  const formik = useFormik<FormikFields>({
    initialValues: {
      email: routeStateEmail || '',
      password: '',
    },
    validationSchema: authValidationSchema(intl),
    validateOnMount: true,
    onSubmit: async ({ email, password }) => {
      const checkEmail = await checkEmailAvailibity({
        variables: {
          email,
        },
      });
      if (checkEmail.data?.checkEmailAvailability?.available) {
        snackbar.error(
          intl.formatMessage(AuthenticationErrorTranslations.emailTaken),
        );
        return;
      }
      networkState.loading();
      await auth({ email, password });
      networkState.succeeded();
    },
  });

  return (
    <Layout withAsideImage>
      <LayoutHeader>
        <h1>{intl.formatMessage(AuthenticationTranslations.title)}</h1>
        <p>{intl.formatMessage(AuthenticationTranslations.subtitle)}</p>
      </LayoutHeader>

      <main>
        <form onSubmit={formik.handleSubmit}>
          <TextInput
            dataCy="email"
            name="email"
            label={intl.formatMessage(AuthenticationTranslations.email)}
            value={formik.values.email}
            onChange={formik.handleChange}
            icon={MailIcon}
            error={formikError(formik.touched, formik.errors, 'email')}
          />
          <PasswordInput
            dataCy="password"
            icon={LockIcon}
            name="password"
            label={intl.formatMessage(AuthenticationTranslations.password)}
            value={formik.values.password || ''}
            onChange={formik.handleChange}
            error={formikError(formik.touched, formik.errors, 'password')}
            forgottenPassword
          />
          <PrimaryButton
            icon={networkState.iconBasedOnNetworkState}
            label={intl.formatMessage(AuthenticationTranslations.signIn)}
            submit
          />
        </form>
        <p className="authentication__no-account">
          {intl.formatMessage(AuthenticationTranslations.noAccount)}
          <Link
            data-cy="no-account-link"
            className="authentication__no-account__signup-link"
            to={Routes.signup}
          >
            {intl.formatMessage(AuthenticationTranslations.signUp)}
          </Link>
        </p>
        <LayoutSeparator>
          {intl.formatMessage(AuthenticationTranslations.or)}
        </LayoutSeparator>

        <LinkedinButton onClick={oauth.signInWithLinkedIn} />
        <PoleEmploiButton onClick={oauth.signInWithFranceTravail} />
      </main>
    </Layout>
  );
}
