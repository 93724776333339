import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import ButtonsGroup from 'components/buttons-group/ButtonsGroup';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import useProfileCompletionUserUpdate from './ProfileCompletionUserUpdate.hook';
import { WantToSharePanelTranslations } from './panels.translations';
import useFormikNetworkState from 'hooks/use-formik-network-state';
import ArrowRightIcon from 'assets/component-icons/ArrowRightIcon';
import './WantToSharePanel.scss';

type FormikValues = {
  wantToShare?: boolean;
};

export default function WantToSharePanel() {
  const intl = useIntl();

  const updateUser = useProfileCompletionUserUpdate();
  const networkState = useFormikNetworkState(ArrowRightIcon);

  const formik = useFormik<FormikValues>({
    initialValues: { wantToShare: undefined },
    validateOnMount: true,
    onSubmit: async ({ wantToShare }) => {
      networkState.loading();
      await updateUser({ wantToShare });
      networkState.succeeded();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <ButtonsGroup>
        <PrimaryButton
          label={intl.formatMessage(
            WantToSharePanelTranslations.wantToPassTitle,
          )}
          onClick={() => {
            formik.setFieldValue('wantToShare', false);
            formik.submitForm();
          }}
          outlined
        />

        <PrimaryButton
          label={intl.formatMessage(
            WantToSharePanelTranslations.wantToShareTitle,
          )}
          icon={networkState.iconBasedOnNetworkState}
          onClick={() => {
            formik.setFieldValue('wantToShare', true);
            formik.submitForm();
          }}
        />
      </ButtonsGroup>
    </form>
  );
}
