import { useLazyQuery } from '@apollo/client';
import { useSnackbar } from '@myjobglasses/account-ui';
import MailIcon from 'assets/component-icons/MailIcon';
import formikError from 'components/formik-helper/formik.helper';
import Layout, {
  LayoutHeader,
  LayoutSeparator,
} from 'components/layout/Layout';
import LinkedinButton from 'components/linkedin-button/LinkedinButton';
import PoleEmploiButton from 'components/pole-emploi-button/PoleEmploiButton';
import PrimaryButton from 'components/primary-button/PrimaryButton';
import TextInput from 'components/text-input/TextInput';
import { useFormik } from 'formik';
import useFormikNetworkState from 'hooks/use-formik-network-state';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import Routes from 'Routes';
import {
  CheckEmailAvailabilityQuery,
  CheckEmailAvailabilityQueryVariables,
} from '../../@types/graphql';
import useAuth from '../authentication/Auth.hook';
import '../authentication/Authentication.scss';
import { CHECK_EMAIL_AVAILABILITY_QUERY } from '../authentication/gql/CheckEmailAvailibity.gql';
import useOAuth from '../authentication/oauth/OAuth.hook';
import {
  SignupErrorTranslations,
  SignupTranslations,
} from './Signup.translations';
import validationSchema from './Signup.validations';

type FormikFields = {
  email: string;
};

export default function Signup() {
  const snackbar = useSnackbar();
  const intl = useIntl();

  const auth = useAuth();
  const oauth = useOAuth();

  const networkState = useFormikNetworkState(null);

  const [checkEmailAvailibity] = useLazyQuery<
    CheckEmailAvailabilityQuery,
    CheckEmailAvailabilityQueryVariables
  >(CHECK_EMAIL_AVAILABILITY_QUERY);

  const formik = useFormik<FormikFields>({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema(intl),
    validateOnMount: true,
    onSubmit: async ({ email }) => {
      const checkEmail = await checkEmailAvailibity({
        variables: {
          email,
        },
      });
      if (!checkEmail.data?.checkEmailAvailability?.available) {
        snackbar.error(intl.formatMessage(SignupErrorTranslations.emailTaken));
        return;
      }
      networkState.loading();
      await auth({ email });
      networkState.succeeded();
    },
  });

  return (
    <Layout withAsideImage>
      <LayoutHeader>
        <h1>{intl.formatMessage(SignupTranslations.title)}</h1>
        <p>{intl.formatMessage(SignupTranslations.subtitle)}</p>
      </LayoutHeader>

      <main>
        <form onSubmit={formik.handleSubmit}>
          <TextInput
            dataCy="email"
            name="email"
            label={intl.formatMessage(SignupTranslations.email)}
            value={formik.values.email}
            onChange={formik.handleChange}
            icon={MailIcon}
            error={formikError(formik.touched, formik.errors, 'email')}
          />
          <PrimaryButton
            icon={networkState.iconBasedOnNetworkState}
            label={intl.formatMessage(SignupTranslations.createAccount)}
            submit
          />
        </form>
        <p className="authentication__no-account">
          {intl.formatMessage(SignupTranslations.existingAccount)}
          <Link
            data-cy="has-account-link"
            className="authentication__no-account__signup-link"
            to={Routes.home}
          >
            {intl.formatMessage(SignupTranslations.signIn)}
          </Link>
        </p>
        <LayoutSeparator>
          {intl.formatMessage(SignupTranslations.or)}
        </LayoutSeparator>

        <LinkedinButton onClick={oauth.signInWithLinkedIn} />
        <PoleEmploiButton onClick={oauth.signInWithFranceTravail} />
      </main>
    </Layout>
  );
}
