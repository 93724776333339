import ArrowRightIcon from 'assets/component-icons/ArrowRightIcon';
import { SettingsContext } from 'contexts/settings/SettingsContext';
import { useFormik } from 'formik';
import useFormikNetworkState from 'hooks/use-formik-network-state';
import { CommonTranslations } from 'i18n/common.translations';
import { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { PrimarySituation } from '../../../@types/graphql';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import RadioInput from '../../../components/radio-input/RadioInput';
import useProfileCompletionUserUpdate from './ProfileCompletionUserUpdate.hook';

type FormikValues = {
  primarySituation?: PrimarySituation['key'];
};

export default function PrimarySituationPanel() {
  const intl = useIntl();

  const userUpdate = useProfileCompletionUserUpdate();
  const networkState = useFormikNetworkState(ArrowRightIcon);

  const settingsContext = useContext(SettingsContext);
  const situations: PrimarySituation[] = useMemo(() => {
    if (!settingsContext?.settings) {
      return [];
    }

    return settingsContext.settings.primarySituations;
  }, [settingsContext?.settings]);

  const formik = useFormik<FormikValues>({
    initialValues: {},
    onSubmit: async ({ primarySituation }) => {
      networkState.loading();
      if (!primarySituation) {
        return;
      }

      await userUpdate({ primarySituation: primarySituation });
      networkState.succeeded();
    },
  });

  const isValid = useMemo(() => {
    return !!formik.values.primarySituation;
  }, [formik.values.primarySituation]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <RadioInput
        dataCy="primary-situation"
        name="primarySituation"
        values={situations.map(({ key, name }) => ({
          value: key,
          translation: name,
        }))}
        onSelection={(key) => formik.setFieldValue('primarySituation', key)}
      />

      <PrimaryButton
        label={intl.formatMessage(CommonTranslations.continue)}
        icon={networkState.iconBasedOnNetworkState}
        submit
        disabled={!isValid}
      />
    </form>
  );
}
