import {
  ApolloError,
  useMutation,
  useQuery,
} from '@apollo/client';
import Routes from 'Routes';
import { useSnackbar } from '@myjobglasses/account-ui';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { goToMarketplaceDashboard } from 'utils/redirections';
import { isHRAdmin } from 'utils/user';
import {
  ComesFromEnum,
  Get_Current_UserQuery,
  Get_Current_UserQueryVariables,
  Sign_InMutation,
  Sign_InMutationVariables,
  Sign_UpMutation,
  Sign_UpMutationVariables,
  SigninErrorEnum,
  SignupErrorEnum,
} from '../../@types/graphql';
import { AuthenticationErrorTranslations } from './Authentication.translations';
import { GET_CURRENT_USER } from './gql/CurrentUser.gql';
import { SIGN_UP_MUTATION } from './gql/SignUp.gql';
import { SIGN_IN_MUTATION } from './gql/Signin.gql';

type AuthParams = {
  email: string;
  password?: string;
  birthdate?: Date;
  highSchoolTeacher?: boolean;
  tutorEmail?: string;
  nonce?: string;
  invitationToken?: string;
  comesFrom?: ComesFromEnum;
};

export default function useAuth() {
  const navigate = useNavigate();
  const intl = useIntl();
  const snackbar = useSnackbar();

  useQuery<Get_Current_UserQuery, Get_Current_UserQueryVariables>(
    GET_CURRENT_USER,
    {
      onCompleted: (data) => {
        if (data.currentUserV2) {
          if (isHRAdmin(data.currentUserV2)) {
            navigate(Routes.segmentation);
            return;
          }
          navigate(Routes.profileCompletion);
        }
      },
    },
  );

  const [signup] = useMutation<Sign_UpMutation, Sign_UpMutationVariables>(
    SIGN_UP_MUTATION,
  );

  const [signin] = useMutation<Sign_InMutation, Sign_InMutationVariables>(
    SIGN_IN_MUTATION,
    {
      refetchQueries: ['GET_CURRENT_USER'],
    },
  );

  return async ({
    email,
    password,
    birthdate,
    tutorEmail,
    nonce,
    highSchoolTeacher,
    invitationToken,
    comesFrom,
  }: AuthParams) => {
    if (password) {
      await signin({
        variables: {
          email,
          password,
        },
      })
        .then(() => {
          goToMarketplaceDashboard();
        })
        .catch((error: ApolloError) => {
          const errorCodes = error.graphQLErrors.map(
            ({ extensions }) => extensions?.code,
          );

          switch (true) {
            case errorCodes.includes(SigninErrorEnum.NotFound):
              snackbar.error(
                intl.formatMessage(AuthenticationErrorTranslations.emailTaken)
              );
              break;
            case errorCodes.includes(SigninErrorEnum.TutorEmailUnconfirmed):
              snackbar.info(
                intl.formatMessage(AuthenticationErrorTranslations.tutorEmailUnconfirmed)
              );
              break;
            case errorCodes.includes(SigninErrorEnum.EmailUnconfirmed):
              snackbar.info(
                intl.formatMessage(AuthenticationErrorTranslations.emailUnconfirmed)
              );
              break;
            default:
              break;
          }
        });
      return;
    }

    await signup({
      variables: {
        email,
        birthdate: birthdate?.toISOString(),
        highSchoolTeacher,
        tutorEmail,
        oauthNonce: nonce,
        invitationToken,
        comesFrom,
      },
    })
      .then(() => {
        window.location.reload();
      })
      .catch((error: ApolloError) => {
        const errorCodes = error.graphQLErrors.map(
          ({ extensions }) => extensions?.code,
        );

        const emailBirthdateState = { state: { email, birthdate } };
        const emailState = { state: { email } };
        const tutorEmailState = {
          state: {
            email: tutorEmail,
            tutorEmail: true,
          },
        };

        switch (true) {
          case errorCodes.includes(SignupErrorEnum.EmailInvalid):
            snackbar.error(
              intl.formatMessage(AuthenticationErrorTranslations.emailInvalid)
            );
            break;
          case errorCodes.includes(SignupErrorEnum.EmailForbiddenDomain):
            snackbar.error(
              intl.formatMessage(AuthenticationErrorTranslations.emailForbiddenDomain)
            );
            break;
          case errorCodes.includes(SignupErrorEnum.TutorEmailBlank):
            navigate(Routes.mustProvideTutorEmail, emailBirthdateState);
            break;
          case errorCodes.includes(SignupErrorEnum.TutorEmailUnconfirmed):
            navigate(Routes.mustConfirmEmail, tutorEmailState);
            break;
          case errorCodes.includes(SignupErrorEnum.EmailUnconfirmed):
            navigate(Routes.mustConfirmEmail, emailState);
            break;
          case errorCodes.includes(SignupErrorEnum.BirthdateBlank):
            navigate(Routes.confirmSignup, emailState);
            break;
          default:
            break;
        }
      });
  };
}
