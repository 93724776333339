import { defineMessages } from 'react-intl';

export default defineMessages({
  success: {
    id: 'ConfirmTutorEmail.success',
    defaultMessage:
      'Félicitations, votre adresse email de tuteur a bien été confirmée.',
  },
  error: {
    id: 'ConfirmTutorEmail.error',
    defaultMessage:
      'Une erreur est survenue dans la confirmation de votre email de tuteur, veuillez nous contacter.',
  },
  alreadyConfirmedError: {
    id: 'ConfirmTutorEmail.alreadyConfirmedError',
    defaultMessage:
      'Vous avec déjà confirmé votre email de tuteur, vous pouvez fermer cette fenêtre.',
  },
  title: {
    id: 'ConfirmTutorEmail.title',
    defaultMessage: 'Félicitations !',
  },
  subtitle: {
    id: 'ConfirmTutorEmail.subtitle',
    defaultMessage: `Vous avez bien validé l'accès au service My job Glasses pour votre enfant.<br></br>
Un email a été envoyé à son adresse email pour l'en informer qui lui permettra d'activer son compte utilisateur.<br></br><br></br>
<i>Aucune action n'est requise de votre part suite à ce message, vous pouvez fermer cette fenêtre.</i>`,
  },
  button: {
    id: 'ConfirmTutorEmail.button',
    defaultMessage: "Retourner à l'écran de connexion",
  },
});
