/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Icon } from '@myjobglasses/account-ui';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import RTFMailTo from '../commons/rich-text-formatting/mail-to/RTFMailTo';
import translations from './CompanyAlreadyCustomer.translations';

import styles from './CompanyAlreadyCustomer.module.scss';

const mjgContact = window.__RUNTIME_CONFIG__.REACT_APP_MJG_CS_CONTACT;

function CompanyAlreadyCustomer() {
  const intl = useIntl();
  const location = useLocation();

  return (
    <div className={styles.companyAlreadyCustomerView}>
      <div className={styles.companyAlreadyCustomer}>
        <div className={styles.iconHeader}>
          <Icon
            name="gpp_maybe"
            className={styles.icon}
          />
        </div>
        <h1 className={styles.title}>
          {intl.formatMessage(translations.title)}
        </h1>
        <p className={styles.description}>
          {intl.formatMessage(translations.description, {
            email: location?.state?.email ?? mjgContact,
            mailTo: RTFMailTo,
          })}
        </p>
      </div>
    </div>
  );
}

export default CompanyAlreadyCustomer;
