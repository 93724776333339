import classNames from 'classnames';
import { useCallback } from 'react';
import './ToggleButton.scss';

export type ToggleStates = 'undefined' | 'on' | 'off';

type ToggleButtonProps = {
  isToggled?: boolean;
  onChange: (isToggled: boolean) => void;
};

function computeDefaultToggleState(isToggled?: boolean): ToggleStates {
  if (isToggled === undefined) {
    return 'undefined';
  }
  return isToggled === true ? 'on' : 'off';
}

export default function ToggleButton({
  isToggled,
  onChange,
}: ToggleButtonProps) {
  const computedToggleState = computeDefaultToggleState(isToggled);
  const handleClick = useCallback(() => {
    if (['undefined', 'off'].includes(computedToggleState)) {
      onChange(true);
    } else {
      onChange(false);
    }
  }, [isToggled, computedToggleState]);

  return (
    <button
      type="button"
      className={classNames(
        'toggle-button',
        `toggle-button--${computedToggleState}`,
      )}
      onClick={handleClick}
    >
      <div
        className={classNames(
          'toggle-button__state-icon',
          'toggle-button__state-icon--off',
        )}
      >
        O
      </div>
      <div
        className={classNames(
          'toggle-button__state-icon',
          'toggle-button__state-icon--on',
        )}
      >
        |
      </div>
      <div className="toggle-button__pin" />
    </button>
  );
}
