import { useContext, useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import TextInput from '../../../components/text-input/TextInput';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import { useIntl } from 'react-intl';
import { otherSituationPanelValidationSchema } from './panels.validation';
import { CommonTranslations } from 'i18n/common.translations';
import useUserContextUpdate from 'contexts/user/UserContextUpdate.hook';
import { UserContext } from 'contexts/user/UserContext';
import SecondaryButton from 'components/secondary-button/SecondaryButton';
import RefreshIcon from 'assets/component-icons/RefreshIcon';
import useFormikNetworkState from 'hooks/use-formik-network-state';
import ClockRewindIcon from 'assets/component-icons/ClockRewindIcon';
import BriefcaseIcon from 'assets/component-icons/BriefcaseIcon';
import BuildingIcon from 'assets/component-icons/BuildingIcon';
import { OtherSituationPanelTranslations } from './panels.translations';
import CheckboxInput from 'components/checkbox-input/CheckboxInput';
import {
  FieldCompletionName,
  PrimarySituationEnum,
} from '../../../@types/graphql';
import formikError from 'components/formik-helper/formik.helper';

type FormikValues = {
  primarySituation: PrimarySituationEnum;
  previousPosition: string;
  previousCompanyName: string;
  previousPositionYearsOfExperiences: number;
  neverWorked: boolean;
};

export default function OtherSituationPanel({
  primarySituation,
}: {
  primarySituation: PrimarySituationEnum;
}) {
  const intl = useIntl();

  const { user } = useContext(UserContext);
  const updateUser = useUserContextUpdate();

  const networkState = useFormikNetworkState();

  const initialPrimarySituationRef = useRef(primarySituation);

  const [initialValues, setInitialValues] = useState<FormikValues>({
    primarySituation: primarySituation,
    previousPosition: user?.previousPosition || '',
    previousCompanyName: user?.previousCompanyName || '',
    previousPositionYearsOfExperiences:
      user?.previousPositionYearsOfExperiences || 0,
    neverWorked: user?.neverWorked || false,
  });

  useEffect(() => {
    if (primarySituation !== initialPrimarySituationRef.current) {
      setInitialValues({
        primarySituation: primarySituation,
        previousPosition: '',
        previousCompanyName:'',
        previousPositionYearsOfExperiences: 0,
        neverWorked: false,
      });
    }
  }, [primarySituation]);

  const formik = useFormik<FormikValues>({
    initialValues: initialValues,
    validationSchema: otherSituationPanelValidationSchema(intl),
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      networkState.loading();
      const response = await updateUser(values);
      response.errors ? networkState.error() : networkState.succeeded();
    },
  });

  return (
    <>
      <TextInput
        name="previousPosition"
        label={intl.formatMessage(
          OtherSituationPanelTranslations.previousJobTitle,
        )}
        icon={BriefcaseIcon}
        value={formik.values.previousPosition}
        onChange={formik.handleChange}
        disabled={
          formik.values.neverWorked &&
          !!user?.editionCompletionStatus?.fieldsCompletion?.find(
            (completionStatus) =>
              completionStatus.name ==
                FieldCompletionName.PreviousCompanyName &&
              completionStatus.disabled
          )
        }
        error={formikError(formik.touched, formik.errors, 'previousPosition')}
      />

      <TextInput
        name="previousCompanyName"
        label={intl.formatMessage(
          OtherSituationPanelTranslations.previousCompanyName,
        )}
        icon={BuildingIcon}
        value={formik.values.previousCompanyName}
        onChange={formik.handleChange}
        disabled={formik.values.neverWorked}
        error={formikError(
          formik.touched,
          formik.errors,
          'previousCompanyName',
        )}
      />

      <TextInput
        name="previousPositionYearsOfExperiences"
        label={intl.formatMessage(
          OtherSituationPanelTranslations.previousYearsOfExperience,
        )}
        icon={ClockRewindIcon}
        value={formik.values.previousPositionYearsOfExperiences}
        type="number"
        onChange={formik.handleChange}
        disabled={formik.values.neverWorked}
      />

      <CheckboxInput
        name="neverWorked"
        label={intl.formatMessage(OtherSituationPanelTranslations.neverWorked)}
        checked={formik.values.neverWorked}
        onChange={formik.handleChange}
        error={formikError(formik.touched, formik.errors, 'neverWorked')}
      />

      <footer>
        <PrimaryButton
          label={intl.formatMessage(CommonTranslations.save)}
          icon={networkState.iconBasedOnNetworkState}
          onClick={formik.handleSubmit}
          accent
          leftIcon
          state={networkState.state}
          disabled={!formik.isValid}
        />
        <SecondaryButton
          label={intl.formatMessage(CommonTranslations.reset)}
          leftIcon={RefreshIcon}
          onClick={() => formik.resetForm()}
          iconButtonWithText
        />
      </footer>
    </>
  );
}
