import * as Yup from 'yup';
import { IntlShape } from 'react-intl';
import { AuthenticationErrorTranslations } from '../authentication/Authentication.translations';

export default (intl: IntlShape) =>
  Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage(AuthenticationErrorTranslations.emailInvalid))
      .required(intl.formatMessage(AuthenticationErrorTranslations.emailRequired))
  });

