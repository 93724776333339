import { LinkedinIcon } from '@myjobglasses/account-ui';
import { useIntl } from 'react-intl';

import { AuthenticationTranslations } from 'scenes/authentication/Authentication.translations';
import styles from './LinkedinButton.module.scss';

type LinkedinButtonProps = {
  onClick: () => void;
};

export default function LinkedinButton({ onClick }: LinkedinButtonProps) {
  const intl = useIntl();
  return (
    <button
      onClick={onClick}
      className={styles.linkedinButton}
      type="button"
    >
      <LinkedinIcon className={styles.linkedinIcon} />
      <span
        data-testid="linkedin-button-text"
        className={styles.linkedinButtonText}
      >
        {intl.formatMessage(AuthenticationTranslations.linkedIn)}
      </span>
    </button>
  );
}
