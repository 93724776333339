import React from 'react';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/client';
import { CREATE_GOUV_SCHOOL_MUTATION, } from 'gqls/Schools.gql';
import { SecondarySituationEnum } from '../../../../@types/graphql';
import PrimaryButton from 'components/primary-button/PrimaryButton';
import SecondaryButton from 'components/secondary-button/SecondaryButton';
import TextInput from 'components/text-input/TextInput';
import useModal from 'components/modal/Modal.hook';
import ButtonsGroup from 'components/buttons-group/ButtonsGroup';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import GooglePlaceInput from 'components/google-place-input/GooglePlaceInput';
import MarkerPinIcon from 'assets/component-icons/MarkerPinIcon';
import useFormikNetworkState from 'hooks/use-formik-network-state';
import './GouvSchoolCreateModal.scss';
import { GouvSchoolCreateModalTranslations } from './GouvSchoolCreateModal.translations';
import { CommonTranslations } from 'i18n/common.translations';
import { gouvSchoolCreateModalValidationSchema } from './gouv-school-create-modal.validation';

interface GouvSchoolCreateModalProps {
  onClose: () => void;
  onSuccess: (school: { name: string; communeName: string }) => void;
  establishmentType: SecondarySituationEnum | undefined;
  departmentLabel: string;
}

type FormikValues = {
  name: string;
  communeName: string;
};

const GouvSchoolCreateModal: React.FC<GouvSchoolCreateModalProps> = ({ onClose, onSuccess, establishmentType,
  departmentLabel, }) => {
  const { formatMessage } = useIntl();
  const intl = useIntl();
  const [, closeModal] = useModal();
  const networkState = useFormikNetworkState();

  const [createGouvSchoolMutation] = useMutation(CREATE_GOUV_SCHOOL_MUTATION);

  const formik = useFormik<FormikValues>({
    initialValues: {
      name: '',
      communeName: '',
    },
    validationSchema: gouvSchoolCreateModalValidationSchema(intl),
    onSubmit: async (values) => {
      networkState.loading();
      try {
        const response = await createGouvSchoolMutation({
          variables: {
            name: values.name,
            communeName: values.communeName,
            establishmentType,
            departmentLabel,
          }
        });
        onSuccess(response.data.createGouvSchool);
        closeModal();
      } catch (error) {
        console.error('Error creating school', error);
      }
      networkState.succeeded();
    },
  });

  return (
    <div className="gouv-school-create-modal">
      <form onSubmit={formik.handleSubmit}>
        <TextInput
          name="name"
          label={formatMessage(GouvSchoolCreateModalTranslations.nameLabel)}
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name ? formik.errors.name : undefined}
        />
        <br />
        <GooglePlaceInput
          name="communeName"
          label={formatMessage(GouvSchoolCreateModalTranslations.cityLabel)}
          icon={MarkerPinIcon}
          onChange={(e) => {
            formik.setFieldValue('communeName', e.description);
          }}
          error={formik.touched.communeName ? formik.errors.communeName : undefined}
        />
        <ButtonsGroup>
          <SecondaryButton
            label={formatMessage(CommonTranslations.cancel)}
            onClick={onClose}
          />
          <PrimaryButton
            label={formatMessage(GouvSchoolCreateModalTranslations.create)}
            onClick={formik.submitForm}
          />
        </ButtonsGroup>
      </form>
    </div>
  );
};

export default GouvSchoolCreateModal;
