import { PropsWithChildren, createContext, useState } from 'react';
import {
  AvailableLanguagesEnum,
  GetUserQuery,
  GetUserQueryVariables,
  UserPartsFragment,
} from '../../@types/graphql';
import { useQuery } from '@apollo/client';
import { GET_USER_QUERY } from './UserContext.gql';
import { useNavigate } from 'react-router-dom';
import Routes from 'Routes';
import { useLanguage } from 'hooks/use-languages/useLanguages';

type UserContextType = {
  user?: UserPartsFragment;
  updateUser?: (result: UserPartsFragment) => void;
  admin?: boolean;
};

export const UserContext = createContext<UserContextType>({});

export function UserContextProvider({ children }: PropsWithChildren) {
  const language = useLanguage();
  const navigate = useNavigate();

  const [user, setUser] = useState<UserPartsFragment>();

  useQuery<GetUserQuery, GetUserQueryVariables>(GET_USER_QUERY, {
    variables: {
      locale:
        language.language === 'fr'
          ? AvailableLanguagesEnum.Fr
          : AvailableLanguagesEnum.En,
    },
    onCompleted: (data) => {
      if (!data.currentUserV2) {
        navigate(Routes.home);
        return;
      }
      setUser(data.currentUserV2);
    },
  });

  function updateUser(result: UserPartsFragment) {
    setUser(result);
  }

  return (
    <UserContext.Provider
      value={{
        user,
        updateUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
