import { useLayoutEffect } from 'react';
import './ThankYouPanel.scss';
import { useIntl } from 'react-intl';
import { ThankYouPanelUpdateTranslations } from './panels.translations';
import { motion } from 'framer-motion';
import AnimatedText from 'components/animated-text/AnimatedText';
import PrimaryButton from 'components/primary-button/PrimaryButton';
import { useProfileCompletionContext } from '../ProfileCompletionContext.hook';
import { PANELS } from '.';
import { useMutation } from '@apollo/client';
import { REQUEST_MODERATION_MUTATION } from 'gqls/Publication.gql';
import useProfileCompletionUserUpdate from './ProfileCompletionUserUpdate.hook';
import { goToMarketplace } from 'utils/redirections';
import {
  RequestModerationMutation,
  RequestModerationMutationVariables,
} from '../../../@types/graphql';

export default function ThankYouPanelUpdate() {
  const { formatMessage } = useIntl();

  const { user } = useProfileCompletionContext();
  const updateUser = useProfileCompletionUserUpdate();
  const { currentPanelIndex } = useProfileCompletionContext();

  const [requestModeration] = useMutation<
    RequestModerationMutation,
    RequestModerationMutationVariables
  >(REQUEST_MODERATION_MUTATION, {
    variables: {
      userId: user?.id || '',
    },
  });

  async function finishCompletion() {
    await updateUser({ showCongratulationsPanel: false });
    goToMarketplace();
  }

  useLayoutEffect(() => {
    if (
      user?.currentUserAuthorizations.canRequestModeration &&
      user?.isModeratable
    ) {
      requestModeration();
    }

    // REFACTO: we should not need this condition to not trigger confettins every frames ...
    if (currentPanelIndex === PANELS.length - 1) {
      setTimeout(() => {
        finishCompletion();
      }, 12000);
    }
  }, [PANELS, currentPanelIndex, user?.isModeratable, requestModeration]);

  return (
    <>
      <motion.h1
        className="thank-you-panel__title"
        initial={{ transform: 'translate3d(0, 25vh, 0)' }}
        animate={{ width: 'fit-content', transform: 'translate3d(0, 0, 0)' }}
        transition={{ duration: 0.5, delay: 4, ease: 'easeInOut' }}
      >
        <AnimatedText
          text={formatMessage(ThankYouPanelUpdateTranslations.title)}
          delay={1}
        />
      </motion.h1>

      <motion.h2
        className="thank-you-panel__subtitle"
        initial={{ opacity: 0, transform: 'translate3d(50px, 0px, 0px)' }}
        animate={{ opacity: 1, transform: 'translate3d(0px, 0px, 0px)' }}
        transition={{ delay: 4.5, ease: 'easeInOut' }}
      >
        {formatMessage(ThankYouPanelUpdateTranslations.subtitle)}
      </motion.h2>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 6, ease: 'easeInOut' }}
        className="thank-you-panel__redirection-block"
      >
        <PrimaryButton
          label={formatMessage(ThankYouPanelUpdateTranslations.goToMarketplaceButton)}
          onClick={finishCompletion}
        />
      </motion.div>
    </>
  );
}
