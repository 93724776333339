import {
  Dispatch,
  PropsWithChildren,
  ReactNode,
  createContext,
  useReducer,
} from 'react';
import Modal from './Modal';
import { AnimatePresence } from 'framer-motion';

export type ModalState = {
  isVisible?: boolean;
  title?: string;
  subtitle?: string;
  content?: ReactNode;
};
const CLOSE_STATE: ModalState = {
  isVisible: false,
  title: undefined,
  subtitle: undefined,
  content: undefined,
};
type ModalActionKind = 'show' | 'close';
type ModalAction = {
  type: ModalActionKind;
  payload: ModalState;
};

export const ModalContext = createContext<Dispatch<ModalAction> | undefined>(
  undefined,
);

function reducer(
  state: ModalState,
  action: Omit<ModalAction, 'visible'>,
): ModalState {
  switch (action.type) {
    case 'show':
      return { ...state, isVisible: true, ...action.payload };
    case 'close':
      return { ...state, ...CLOSE_STATE };
  }
}

export default function ModalContextProvider({ children }: PropsWithChildren) {
  const [state, dispatch] = useReducer(reducer, CLOSE_STATE);

  return (
    <ModalContext.Provider value={dispatch}>
      {children}

      <AnimatePresence>
        {state.isVisible && <Modal title={state.title}>{state.content}</Modal>}
      </AnimatePresence>
    </ModalContext.Provider>
  );
}
