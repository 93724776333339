import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Layout, { LayoutHeader } from 'components/layout/Layout';
import Routes from 'Routes';
import {
  CheckEmailAvailabilityQuery,
  CheckEmailAvailabilityQueryVariables,
  OauthMutation,
  OauthMutationVariables,
  OauthProviderEnum,
} from '../../../@types/graphql';
import { OAUTH_MUTATION } from './gql/OAuth.gql';
import { CHECK_EMAIL_AVAILABILITY_QUERY } from '../gql/CheckEmailAvailibity.gql';

export default function LinkedInOAuthCallback() {
  const location = useLocation();
  const navigate = useNavigate();

  const [checkEmailAvailibity] = useLazyQuery<
    CheckEmailAvailabilityQuery,
    CheckEmailAvailabilityQueryVariables
  >(CHECK_EMAIL_AVAILABILITY_QUERY);
  const [oauthLinkedIn] = useMutation<OauthMutation, OauthMutationVariables>(
    OAUTH_MUTATION,
    {
      onCompleted: async ({ oauth }) => {
        if (oauth) {
          const { email, firstName, lastName, nonce } = oauth;
          const emailAvailability = await checkEmailAvailibity({
            variables: { email },
          });

          if (emailAvailability.data?.checkEmailAvailability.available) {
            localStorage.setItem(
              email,
              JSON.stringify({ firstName, lastName }),
            );
            navigate(Routes.signup, { state: { email, nonce } });
          } else {
            navigate(Routes.home, { state: { email } });
          }
        }
      },
    },
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const state = searchParams.get('state');

    oauthLinkedIn({
      variables: {
        authorizationCode: searchParams.get('code')!,
        provider: OauthProviderEnum.Linkedin,
        redirectUri: `${window.location.origin}${window.location.pathname}?state=${state}`,
      },
    });
  }, [location]);

  return (
    <Layout>
      <LayoutHeader>
        <h1>Nous interrogeons les services de LinkedIn ...</h1>
      </LayoutHeader>
    </Layout>
  );
}
