import { ErrorResponse, onError } from '@apollo/client/link/error';
import { appsignal } from './appsignal';

export const networkErrorAppsignalHandler = onError(
  ({ graphQLErrors, networkError, operation }: ErrorResponse) => {
    if (!graphQLErrors && networkError && 'response' in networkError) {
      /* eslint-disable */
      appsignal.sendError(
        new Error(
          `Network - error ${networkError.response.status} - ${operation.operationName}`,
        ),
        (span) => {
          span.setTags({
            operationName: operation.operationName,
            status: networkError.response.status.toString(),
            statusText: networkError.response.statusText,
            type: networkError.response.type,
            url: networkError.response.url,
          });
        },
      );
      /* eslint-enable */
    }
  },
);
