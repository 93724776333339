import { defineMessages } from 'react-intl';

export const ConfirmSignupTranslations = defineMessages({
  title: {
    id: 'ConfirmSignup.title',
    defaultMessage: "Confirmation d'inscription",
  },
  subtitle: {
    id: 'ConfirmSignup.subtitle',
    defaultMessage:
      'Renseignez les informations suivantes afin de confirmer votre inscription.',
  },
  email: {
    id: 'Signup.email',
    defaultMessage: 'Email',
  },
  birthdate: {
    id: 'ConfirmSignup.birthdate',
    defaultMessage: 'Date de naissance',
  },
  cgu: {
    id: 'ConfirmSignup.cgu',
    defaultMessage:
      "J'ai lu et j'accepte les <cguLink>Conditions Générales d'Utilisation</cguLink>, et la <privacyLink>Politique de Protection des Données Personnelles</privacyLink>(voir la version simplifiée <cguLinkMinor>pour les mineurs numériques</cguLinkMinor>).",
  },
  submit: {
    id: 'ConfirmSignup.submit',
    defaultMessage: "C'est parti !",
  },
  comesFromLabel: {
    id: 'ConfirmSignup.comesFromLabel',
    defaultMessage: 'Comment nous avez-vous connus ?',
  },
});

export const InvitationTranslations = defineMessages({
  invitationTokenInvalid: {
    id: 'InvitationToken.invitationTokenInvalid',
    defaultMessage: "Cette invitation n'est pas valide",
  },
  invitationTokenAlreadyUsed: {
    id: 'InvitationToken.invitationTokenAlreadyUsed',
    defaultMessage: 'Cette invitation est déjà utilisée',
  },
});
