import XIcon from 'assets/component-icons/XIcon';
import { MessageDescriptor, useIntl } from 'react-intl';

import './RemovableTag.scss';

type RemovableTagProps<T> = {
  value: T;
  name?: string;
  translation?: MessageDescriptor;
  onRemove: (value: T) => void;
};

export default function RemovableTag<T>({
  value,
  name,
  translation,
  onRemove,
}: RemovableTagProps<T>) {
  const intl = useIntl();

  return (
    <button
      type="button"
      className="removable-tag"
      onClick={() => onRemove(value)}
    >
      <span>{translation ? intl.formatMessage(translation) : name}</span>
      <XIcon />
    </button>
  );
}
