import { useMutation } from '@apollo/client';
import ArrowRightIcon from 'assets/component-icons/ArrowRightIcon';
import PasscodeIcon from 'assets/component-icons/PasscodeIcon';
import formikError from 'components/formik-helper/formik.helper';
import { useFormik } from 'formik';
import {
  JOIN_PROGRAM_WITH_CODE_MUTATION,
  JOIN_PROGRAM_WITH_INVITATION_MUTATION,
} from 'gqls/ProgramAffiliation.gql';
import useFormikNetworkState from 'hooks/use-formik-network-state';
import { CommonTranslations } from 'i18n/common.translations';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  JoinProgramWithCodeMutation,
  JoinProgramWithCodeMutationVariables,
  JoinProgramWithInvitationMutation,
  JoinProgramWithInvitationMutationVariables,
} from '../../../@types/graphql';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import RadioInput from '../../../components/radio-input/RadioInput';
import TextInput from '../../../components/text-input/TextInput';
import { BinaryResponseTranslations } from '../../../i18n/binary-response.translations';
import { BinaryResponse } from '../../../models/BinaryResponse.enum';
import { ProgramAffiliationPanelTranslations } from './panels.translations';
import { programAffiliationPanelValidationSchema } from './panels.validation';
import useProfileCompletionUserUpdate from './ProfileCompletionUserUpdate.hook';

type FormikValues = {
  followingVocationProgram?: BinaryResponse;
  programAffiliationCode: string;
};

export default function ProgramAffiliationPanel() {
  const intl = useIntl();

  const updateUser = useProfileCompletionUserUpdate();
  const [joinProgramWithCode] = useMutation<
    JoinProgramWithCodeMutation,
    JoinProgramWithCodeMutationVariables
  >(JOIN_PROGRAM_WITH_CODE_MUTATION);
  const [joinProgramWithInvitation] = useMutation<
    JoinProgramWithInvitationMutation,
    JoinProgramWithInvitationMutationVariables
  >(JOIN_PROGRAM_WITH_INVITATION_MUTATION);
  const networkState = useFormikNetworkState(ArrowRightIcon);

  function joinProgramDirectlyIfNeeded() {
    const programCode = localStorage.getItem('program_code');
    if (programCode) {
      joinProgramWithCode({ variables: { programCode } });
      localStorage.removeItem('program_code');
      updateUser({ showVocationPanel: false });
    }

    const invitationId = localStorage.getItem('program_invitation_id');
    if (invitationId) {
      joinProgramWithInvitation({ variables: { invitationId } });
      localStorage.removeItem('program_invitation_id');
      updateUser({ showVocationPanel: false });
    }
  }

  useEffect(() => {
    joinProgramDirectlyIfNeeded();
  }, []);

  const formik = useFormik<FormikValues>({
    initialValues: {
      programAffiliationCode: '',
    },
    validationSchema: programAffiliationPanelValidationSchema(intl),
    validateOnChange: false,
    onSubmit: async ({ followingVocationProgram, programAffiliationCode }) => {
      networkState.loading();
      if (followingVocationProgram === BinaryResponse.No) {
        await updateUser({ showVocationPanel: false });
      } else {
        const response = await joinProgramWithCode({
          variables: { programCode: programAffiliationCode },
        });
        if (response.data) {
          await updateUser({ showVocationPanel: false });
        }
      }
      networkState.succeeded();
    },
  });

  return (
    <form>
      <RadioInput
        dataCy="program"
        name="program"
        values={Object.values(BinaryResponse).map((resp) => ({
          value: resp,
          translation: intl.formatMessage(BinaryResponseTranslations[resp]),
        }))}
        onSelection={(value) =>
          formik.setFieldValue('followingVocationProgram', value)
        }
        error={formik.errors.followingVocationProgram}
      />

      {formik.values.followingVocationProgram === BinaryResponse.Yes && (
        <TextInput
          icon={PasscodeIcon}
          name="programAffiliationCode"
          label={intl.formatMessage(
            ProgramAffiliationPanelTranslations.codeLabel,
          )}
          placeholder={intl.formatMessage(
            ProgramAffiliationPanelTranslations.codePlaceholder,
          )}
          value={formik.values.programAffiliationCode}
          onChange={formik.handleChange}
          error={formikError(
            formik.touched,
            formik.errors,
            'programAffiliationCode',
          )}
        />
      )}

      {/* TODO: Add late button */}

      <PrimaryButton
        label={intl.formatMessage(CommonTranslations.continue)}
        icon={networkState.iconBasedOnNetworkState}
        onClick={formik.handleSubmit}
        disabled={!formik.dirty}
      />
    </form>
  );
}
