import { useCallback, useContext } from 'react';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import { OrientationPanelTranslations } from './panels.translations';
import StaticAutocompleteMultiSelectInput from '../../../components/static-autocomplete-multi-select-input/StaticAutocompleteMultiSelectInput';
import useUserContextUpdate from 'contexts/user/UserContextUpdate.hook';
import { UserContext } from 'contexts/user/UserContext';
import { CommonTranslations } from 'i18n/common.translations';
import SecondaryButton from 'components/secondary-button/SecondaryButton';
import RefreshIcon from 'assets/component-icons/RefreshIcon';
import useFormikNetworkState from 'hooks/use-formik-network-state';
import './OrientationPanel.scss';
import RemovableTag from 'components/removable-tag/RemovableTag';
import AutocompleteAddableMultiSelectInput from 'components/autocomplete-addable-multi-select-input/AutocompleteAddableMultiSelectInput';
import { SettingsContext } from 'contexts/settings/SettingsContext';
import { RecommendedQualificationsTranslations } from 'i18n/recommended-qualifications.translations';

type FormikValues = {
  recommendedQualifications: string[];
  mandatorySkills: string[];
};

export default function OrientationPanel() {
  const intl = useIntl();
  const { user } = useContext(UserContext);
  const updateUser = useUserContextUpdate();
  const networkState = useFormikNetworkState();

  const settingsContext = useContext(SettingsContext);

  const formik = useFormik<FormikValues>({
    initialValues: {
      recommendedQualifications: user?.recommendedQualifications || [],
      mandatorySkills: user?.mandatorySkills || [],
    },
    onSubmit: async ({ recommendedQualifications, mandatorySkills }) => {
      networkState.loading();
      const response = await updateUser({
        recommendedQualifications: recommendedQualifications,
        mandatorySkills,
      });
      response.errors ? networkState.error() : networkState.succeeded();
    },
  });

  const addRecommendedQualification = useCallback(
    (diploma: string) => {
      if (!formik.values.recommendedQualifications.includes(diploma))
        formik.setFieldValue('recommendedQualifications', [
          ...formik.values.recommendedQualifications,
          diploma,
        ]);
    },
    [formik.values.recommendedQualifications],
  );

  const removeRecommendedQualification = useCallback(
    (diploma: string) => {
      formik.setFieldValue(
        'recommendedQualifications',
        formik.values.recommendedQualifications.filter((v) => v !== diploma),
      );
    },
    [formik.values.recommendedQualifications],
  );

  const addMandatorySkill = useCallback(
    (mandatorySkill: string) => {
      if (!formik.values.mandatorySkills.includes(mandatorySkill))
        formik.setFieldValue('mandatorySkills', [
          ...formik.values.mandatorySkills,
          mandatorySkill,
        ]);
    },
    [formik.values.mandatorySkills],
  );

  const removeMandatorySkill = useCallback(
    (mandatorySkill: string) => {
      formik.setFieldValue(
        'mandatorySkills',
        formik.values.mandatorySkills.filter((v) => v !== mandatorySkill),
      );
    },
    [formik.values.mandatorySkills],
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <StaticAutocompleteMultiSelectInput
        name="recommendedQualifications"
        label={intl.formatMessage(OrientationPanelTranslations.labelDiploma)}
        values={(
          settingsContext?.settings?.recommendedQualifications || []
        ).map((diploma) => ({
          value: diploma,
          translation: intl.formatMessage(
            RecommendedQualificationsTranslations[
              diploma as keyof typeof RecommendedQualificationsTranslations
            ],
          ),
        }))}
        selectedValues={formik.values.recommendedQualifications || []}
        onChange={addRecommendedQualification}
      />

      <div className="orientation-panel__tags">
        {formik.values.recommendedQualifications.map((diploma) => (
          <RemovableTag
            key={diploma}
            value={diploma}
            translation={
              RecommendedQualificationsTranslations[
                diploma as keyof typeof RecommendedQualificationsTranslations
              ]
            }
            name={
              settingsContext?.settings?.recommendedQualifications.find(
                (key) => key === diploma,
              ) || (diploma as string)
            }
            onRemove={removeRecommendedQualification}
          />
        ))}
      </div>

      <AutocompleteAddableMultiSelectInput
        name="mandatorySkills"
        label={intl.formatMessage(OrientationPanelTranslations.labelSkills)}
        dataFetchURL=""
        onChange={addMandatorySkill}
      />

      <div className="orientation-panel__tags">
        {formik.values.mandatorySkills.map((mandatorySkill) => (
          <RemovableTag
            key={mandatorySkill}
            value={mandatorySkill}
            name={mandatorySkill}
            onRemove={removeMandatorySkill}
          />
        ))}
      </div>

      <footer>
        <PrimaryButton
          label={intl.formatMessage(CommonTranslations.save)}
          icon={networkState.iconBasedOnNetworkState}
          submit
          accent
          leftIcon
          state={networkState.state}
          disabled={!formik.dirty}
        />
        <SecondaryButton
          label={intl.formatMessage(CommonTranslations.reset)}
          leftIcon={RefreshIcon}
          onClick={() => formik.resetForm()}
          iconButtonWithText
        />
      </footer>
    </form>
  );
}
