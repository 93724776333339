import { useContext } from 'react';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import { maxMeetingsPerMonthPanelValidationSchema } from './panels.validation';
import { CommonTranslations } from 'i18n/common.translations';
import useUserContextUpdate from 'contexts/user/UserContextUpdate.hook';
import SaveIcon from 'assets/component-icons/SaveIcon';
import SecondaryButton from 'components/secondary-button/SecondaryButton';
import RefreshIcon from 'assets/component-icons/RefreshIcon';
import { UserContext } from 'contexts/user/UserContext';
import PrimaryButton from 'components/primary-button/PrimaryButton';
import RangeInput from 'components/range-input/RangeInput';
import {
  DEFAULT_MAX_MEETINGS_PER_MONTH,
  MAX_MEETINGS_PER_MONTH_RANGE,
} from 'constants/profile.constant';
import HeartHandIcon from 'assets/component-icons/HeartHandIcon';
import { MaxMeetingsPerMonthPanelTranslations } from './panels.translations';
import useFormikNetworkState from 'hooks/use-formik-network-state';

type FormikValues = {
  maxMeetingsPerMonth: number;
};

export default function MaxMeetingsPerMonthPanel() {
  const intl = useIntl();

  const { user } = useContext(UserContext);
  const updateUser = useUserContextUpdate();

  const networkState = useFormikNetworkState();

  const formik = useFormik<FormikValues>({
    initialValues: {
      maxMeetingsPerMonth:
        user?.maxMeetingsPerMonth || DEFAULT_MAX_MEETINGS_PER_MONTH,
    },
    validationSchema: maxMeetingsPerMonthPanelValidationSchema,
    validateOnMount: true,
    onSubmit: async ({ maxMeetingsPerMonth }) => {
      networkState.loading();
      const response = await updateUser({
        maxMeetingsPerMonth,
      });
      response.errors ? networkState.error() : networkState.succeeded();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <RangeInput
        name="maxMeetingsPerMonth"
        icon={HeartHandIcon}
        label={intl.formatMessage(MaxMeetingsPerMonthPanelTranslations.quota)}
        value={formik.values.maxMeetingsPerMonth}
        onChange={(v) => formik.setFieldValue('maxMeetingsPerMonth', v)}
        min={MAX_MEETINGS_PER_MONTH_RANGE.min}
        max={MAX_MEETINGS_PER_MONTH_RANGE.max}
        suffix={intl.formatMessage(
          MaxMeetingsPerMonthPanelTranslations.quotaOption,
        )}
      />

      <footer>
        <PrimaryButton
          label={intl.formatMessage(CommonTranslations.save)}
          icon={networkState.iconBasedOnNetworkState}
          state={networkState.state}
          submit
          accent
          leftIcon
        />
        <SecondaryButton
          label={intl.formatMessage(CommonTranslations.reset)}
          leftIcon={RefreshIcon}
          onClick={() => formik.resetForm()}
          iconButtonWithText
        />
      </footer>
    </form>
  );
}
